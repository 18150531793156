import axios from 'axios';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function ForgetPassword() {

    const [email, setEmail] = useState(null)
     let navigate =   useNavigate()

    const handelEmailChange = (event) => {
        setEmail(event.target.value);
      };

      function forgetPassword() {
        axios.post(`https://kanza-back-end.onrender.com/users/forgot` , {
            "email":email
        })
        .then(response => {
            // console.log(response);
            toast.success(response.data.message)
          }).catch(err  => {
            console.log(err);
            toast.error(err.response.data.message)
          })
      }


  return <>
   <section>
        <div className="container py-4 mt-md-5">
          <div className="row mt-3 pt-2">
          <div className="col-md-12 main-cover mb-5">
                        <h2 className='cover-text'>Forget Passoword</h2>
                    </div>

                    <div className='w-50 mx-auto py-5 my-5 text-end'>
                        <input type="email" className='form-control' placeholder='Enter Your email' onChange={handelEmailChange}/>
                        <button className='btn main-clr text-white mt-3 mb-5' onClick={forgetPassword}>Submit</button>
                    </div>
          </div>
        </div>
      </section>

  </>
}
