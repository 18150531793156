import React, { useEffect } from 'react'
import { Link , useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';

export default function Verify() {
        
        let {id} =  useParams()

        let navigate = useNavigate()


    async function postId(id) {
        axios.post(`https://kanza-back-end.onrender.com/users/verify/${id}`).then((response)=> {
        // console.log(response.data.token);
        localStorage.setItem("token", response.data.token);
        navigate('/')
        })
            .catch((err) => {
            console.log(err.response.data.message)
            // navigate('/')
        }
        )
    }

    useEffect(()=>{
        postId(id)
    },[])




  return (
    <section className='my-5 py-5 d-flex justify-content-center align-items-center vh-100 flex-column'>
        <h1 className='text-capitalize'>successfully verify your email address</h1>
        <Link to={'/'}>
        <button className='btn main-clr text-white px-5 '>Go Home</button>
        </Link>
    </section>
  )
}
