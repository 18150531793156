import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
export default function Offer() {
  const [banners, setBanners] = useState([]);

  async function getbanners() {
    let { data } = await axios.get(
      `https://kanza-back-end.onrender.com/promotionalbanners`
    );
    setBanners(data);
  }

  useEffect(() => {
    getbanners();
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (banners.length === 1) {
    return (
      <>
        {banners.map((banner) => (
          <div className="w-100 position-relative container mt-5">
            <img
              src={banner.image}
              style={{ minHeight: "350px", maxHeight: "350px" }}
              className="w-100"
              alt=""
            />
            <Link to="/shop" className="btn btn-offer main-clr text-white px-5">
              SHOP NOW
            </Link>
          </div>
        ))}
      </>
    );
  } else if (banners.length === 0) {
    return;
  }

  return (
    <>
      <section className="py-5 my-5 container">
        <Slider {...settings}>
          {banners.map((banner) => (
            <div className="w-100 position-relative">
              <img
                src={banner.image}
                style={{ minHeight: "350px", maxHeight: "350px" }}
                className="w-100"
                alt=""
                to="/shop"
              />
              <Link
                onClick={() => {
                  localStorage.removeItem("categoryId");
                }}
                className="btn btn-offer main-clr text-white px-5"
              >
                SHOP NOW
              </Link>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
}
