import React from 'react'
import { Link } from 'react-router-dom'

export default function TermsAndPolicy() {
  return <>
  <section>
            <div className='container py-5 mt-md-5'>
                <div className="row mt-md-4">
                    <div className="col-md-12 main-cover">
                        <h2 className='cover-text'>Terms & Policy</h2>
                    </div>
                    <div className="col-md-12">
                        <h4 className='text-center my-3'>Terms and Conditions for Online Fashion Brand</h4>
                        <p className='text-center'>Welcome to Kanza! These Terms and Conditions govern your use of our website, products, and services. By accessing or using our website, you agree to comply with these terms. Please read them carefully.</p>
                        <ol>
                            <li>Acceptance of Terms: By accessing or using our website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not access the website or use our services.</li>
                            <li>Online Store: Our website allows you to browse, purchase, and review information about our products. By placing an order through our website, you acknowledge that you are at least 18 years old or have permission from a parent or guardian.</li>
                            <li>Product Information: We make every effort to display accurate product information, including descriptions, prices, and images. However, we cannot guarantee that all information is accurate, complete, or current. We reserve the right to modify or update product information at any time.</li>
                            <li>Orders and Payments: When you place an order, you agree to provide accurate and complete information. You authorize us to charge your provided payment method for the total amount of the order, including taxes, shipping, and handling fees. We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing, or suspected fraud.</li>
                            <li>Shipping and Delivery: We aim to process and ship orders promptly, but delivery times may vary depending on your location and other factors beyond our control. We are not responsible for delays or errors caused by shipping carriers.</li>
                            <li>Returns and Exchanges: We accept returns and exchanges within 14 days of delivery, subject to certain conditions. Please review our Return Policy for more information on eligibility and procedures.</li>
                            <li>Intellectual Property: All content on our website, including text, images, logos, and trademarks, is the property of Kanza or its licensors and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.</li>
                            <li>Privacy Policy: Our Privacy Policy governs the collection, use, and disclosure of personal information provided by users of our website. By using our website, you consent to the terms of our Privacy Policy.</li>
                            <li>Limitation of Liability: To the fullest extent permitted by law, we disclaim all warranties, express or implied, regarding the accuracy, reliability, or availability of our website or products. We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website or products.</li>
                            <li>Changes to Terms: We reserve the right to modify or update these Terms and Conditions at any time without prior notice. By continuing to use our website after any changes, you agree to be bound by the revised terms.</li>
                        </ol>
                        <strong>If you have any questions or concerns, please <Link className='text-black' to={'/contactus'}> contact us.</Link></strong>
                        <p className='fw-bold'>Thank you for choosing Kanza! We hope you enjoy your shopping experience.</p>
                    </div>
                </div>
            </div>
        </section>


  </>
}
