import React from "react";
export default function NotFound() {
  return (
    <>
       <section>
        <div className="container py-5 mt-5">
          <div className="row mt-3 pt-2">
          <div className="col-md-12 main-cover mb-5">
                        <h2 className='cover-text'>Not Found Page</h2>
                    </div>
                    <div className="my-5 py-5 main-clr-text2 ms-md-4 d-flex justify-content-center align-items-center">
        <h1 className="my-5 me-2">Not Found Page</h1>
        <i className="fa-solid fa-bug fs-1"></i>
      </div>
          </div>
        </div>
      </section>
    </>
  );
}
