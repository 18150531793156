import React, { useContext, useEffect, useState } from "react";
import starIcon from "../../Assets/Icons/star.png";
import productButton from "../../Assets/Icons/span.f-badge.png";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import toast from "react-hot-toast";
import { CartContext } from "../Context/userContext";

export default function OurProducts({ title }) {
  let { setNumberOfCartItems, numberOfCartItems, getCart } =
    useContext(CartContext);

  const [loading, setLoading] = useState(false);

  async function addToCart(productId, varianId) {
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToCart`,
        {
          productId: productId,
          variantId: varianId,
          quantity: 1,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setNumberOfCartItems(response?.data?.cart?.products?.length);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          className: "text-center",
        });
      });
  }
  const [product, setProduct] = useState([]);
  const sortedProducts = product.map((product) => {
    const sortedVariants = [...product.variants].sort((a, b) => {
      if (a.isAvailable && !b.isAvailable) return -1;
      if (!a.isAvailable && b.isAvailable) return 1;
      return 0;
    });
  
    return {
      ...product,
      variants: sortedVariants,
    };
  });

  async function getproducts() {
    let { data } = await axios.get(
      `https://kanza-back-end.onrender.com/products/all?&page=1&perPage=10`
    );
    // console.log(data.products);
    setProduct(data.products);
  }
  useEffect(() => {
    getproducts();
  }, []);

  const settings = {
    infinite: true,
    autoplay: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    initialSlide: 0, // Add this prop to set the initial slide to 0
  };

  async function addToWishList(productId, varianId) {
    setLoading(true);

    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToWishlist`,
        {
          productId: productId,
          variantId: varianId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        getproducts2();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
    setLoading(false);
  }
  async function getproducts2() {
    if (localStorage.getItem("token")) {
      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/products/all?&page={1}&perPage={10}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setProduct(data.products);
    } else {
      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/products/all?&page={1}&perPage={10}`
      );
      setProduct(data?.products);
    }
  }

  return (
    <>
      <section className="container pb-5">
        <div className="container OurProducts">
          <h3 className="py-5 position-relative">
            {title ? title : "Our Products"}
          </h3>
          <div className="row my-4 w-100 mx-auto flex-column ms-sm-0 ms-1">
            {product.length === 1 ? (
              sortedProducts.map((product, index) => (
                <div
                  key={index}
                  className="col-md-3 px-md-2 px-0  col-6 cursor-pointer product"
                >
                  <div className="producthead">
                    {product.variants[0]?.isSale ? (
                      <div className="saleCard d-flex justify-content-around me-md-3 ">
                        <p className="text-white pt-1">
                          Sale {product.variants[0].salePercentage}%
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/productdetails/${product._id}`}
                    >
                      <img
                        src={product.images[0]}
                        className="w-100 h-100"
                        // style={{ minHeight: "513px", maxHeight: "513px" }}
                        alt="ourProducts1"
                      />{" "}
                    </Link>
                    {product.variants[0]?.isAvailable ? (
                      <div className="product-layer d-flex align-items-center justify-content-end ms-md-1">
                        {localStorage.getItem("token") ? (
                          <img
                            src={productButton}
                            onClick={() => {
                              addToCart(product._id, product.variants[0]._id);
                            }}
                            width={170}
                            className="me-2 mt-1 img2"
                          />
                        ) : (
                          <Link
                            to={"/login"}
                            className="text-decoration-none"
                            onClick={() => toast.error("please login first")}
                          >
                            <img
                              src={productButton}
                              width={170}
                              className="me-2 mt-1 img2"
                            />
                          </Link>
                        )}

                        {localStorage.getItem("token") ? (
                          <div
                            className="wishlistbtn2 mb-1"
                            onClick={() => {
                              addToWishList(
                                product._id,
                                product.variants[0]._id
                              );
                            }}
                          >
                            {product.variants[0].isFav ? (
                              <i className="fa-solid fa-heart text-white "></i>
                            ) : (
                              <i className="fa-regular fa-heart text-white"></i>
                            )}
                          </div>
                        ) : (
                          <Link
                            to={"/login"}
                            className="text-decoration-none"
                            onClick={() => toast.error("please login first")}
                          >
                            <div className="wishlistbtn2 mb-1">
                              <i className="fa-regular fa-heart text-white"></i>
                            </div>
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div className="product-layer ms-4 d-flex align-items-center justify-content-end">
                        <div className="btn main-clr text-white px-4 py-1">
                          Out Of Stock
                        </div>
                        <div
                          className="wishlistbtn2 mb-1 ms-2"
                          onClick={() => {
                            addToWishList(product._id, product.variants[0]._id);
                          }}
                        >
                          {product.variants[0].isFav ? (
                            <i className="fa-solid fa-heart text-white"></i>
                          ) : (
                            <i className="fa-regular fa-heart text-white"></i>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <Link
                    className="text-decoration-none text-dark"
                    to={`/productdetails/${product._id}`}
                  >
                    <div className="text-center">
                      <h4 className="h5 text-center pt-3">{product.name}</h4>
                      <div className="d-flex w-75 justify-content-evenly mx-auto ourProductstext">
                        {product.variants[0]?.isSale ? (
                          <div className="d-flex">
                            <p className="me-3">
                              {product.variants[0]?.price} EGP
                            </p>
                            <p className="text-decoration-line-through text-muted">
                              {Number(product.variants[0]?.price) +
                                Number(product.variants[0]?.saleValue)}
                              EGP
                            </p>
                          </div>
                        ) : (
                          <p>
                            {product.minPrice
                              ? product.minPrice.toLocaleString() + " EGP"
                              : "N/A"}
                          </p>
                        )}
                        {product?.rating === 0 ? (
                          ""
                        ) : (
                          <>
                            <p> | </p>
                            <div className="text-end ">
                              {product.rating === 1 ? (
                                <>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                </>
                              ) : (
                                ""
                              )}
                              {product.rating === 2 ? (
                                <>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star rating-clr "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                </>
                              ) : (
                                ""
                              )}
                              {product.rating === 3 ? (
                                <>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                  <i className="fa-solid font-sm fa-star "></i>
                                </>
                              ) : (
                                ""
                              )}
                              {product.rating === 4 ? (
                                <>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star rating-clr "></i>
                                  <i className="fa-solid font-sm fa-star  "></i>
                                </>
                              ) : (
                                ""
                              )}
                              {product.rating === 5 ? (
                                <>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  <i className="fa-solid font-sm fa-star rating-clr "></i>
                                  <i className="fa-solid font-sm fa-star rating-clr"></i>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <Slider {...settings}>
                {product.map((product, index) => (
                  <div
                    key={index}
                    className="col-md-3 px-md-2 px-0  col-6 cursor-pointer product"
                  >
                    <div className="producthead">
                      {product.variants[0]?.isSale ? (
                        <div className="saleCard d-flex justify-content-around me-md-3 ">
                          <p className="text-white pt-1">
                            Sale {product.variants[0].salePercentage}%
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <Link
                        className="text-decoration-none text-dark"
                        to={`/productdetails/${product._id}`}
                      >
                        <img
                          src={product?.images[0]}
                          className="w-100 h-100"
                          // style={{ minHeight: "513px", maxHeight: "513px" }}
                          alt="ourProducts1"
                        />{" "}
                      </Link>
                      {product?.variants[0]?.isAvailable ? (
                        <div className="product-layer d-flex align-items-center justify-content-end ms-md-1">
                          {localStorage.getItem("token") ? (
                            <img
                              src={productButton}
                              onClick={() => {
                                addToCart(
                                  product?._id,
                                  product?.variants[0]?._id
                                );
                              }}
                              width={170}
                              className="me-2 mt-1 img2"
                            />
                          ) : (
                            <Link
                              to={"/login"}
                              className="text-decoration-none"
                              onClick={() => toast.error("please login first")}
                            >
                              <img
                                src={productButton}
                                width={170}
                                className="me-2 mt-1 img2"
                              />
                            </Link>
                          )}

                          {localStorage.getItem("token") ? (
                            <div
                              className="wishlistbtn2 mb-1"
                              onClick={() => {
                                addToWishList(
                                  product?._id,
                                  product?.variants[0]?._id
                                );
                              }}
                            >
                              {product?.variants[0]?.isFav ? (
                                <i className="fa-solid fa-heart text-white "></i>
                              ) : (
                                <i className="fa-regular fa-heart text-white"></i>
                              )}
                            </div>
                          ) : (
                            <Link
                              to={"/login"}
                              className="text-decoration-none"
                              onClick={() => toast.error("please login first")}
                            >
                              <div className="wishlistbtn2 mb-1">
                                <i className="fa-regular fa-heart text-white"></i>
                              </div>
                            </Link>
                          )}
                        </div>
                      ) : (
                        <div className="product-layer ms-4 d-flex align-items-center justify-content-end">
                          <div className="btn main-clr text-white px-4 py-1">
                            Out Of Stock
                          </div>
                          <div
                            className="wishlistbtn2 mb-1 ms-2"
                            onClick={() => {
                              addToWishList(
                                product?._id,
                                product?.variants[0]?._id
                              );
                            }}
                          >
                            {product?.variants[0]?.isFav ? (
                              <i className="fa-solid fa-heart text-white"></i>
                            ) : (
                              <i className="fa-regular fa-heart text-white"></i>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/productdetails/${product?._id}`}
                    >
                      <div className="text-center">
                        <h4 className="h5 text-center pt-3">{product?.name}</h4>
                        <div className="d-flex w-75 justify-content-evenly mx-auto ourProductstext text-muted fw-bold">
                          {product?.variants[0]?.isSale ? (
                            <div className="d-flex">
                              <p className="text-decoration-line-through text-muted fw-bold">
                                {parseInt(product.variants[0].price) +
                                  parseInt(product.variants[0].saleValue)}
                                EGP
                              </p>
                              <p className="ms-1 text-danger fw-bold">
                                {product.variants[0].price} EGP
                              </p>
                            </div>
                          ) : (
                            <p>{product.minPrice.toLocaleString()} EGP</p>
                          )}
                          {product?.rating === 0 ? (
                            ""
                          ) : (
                            <>
                              <p> | </p>
                              <div className="text-end ">
                                {product?.rating === 1 ? (
                                  <>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product?.rating === 2 ? (
                                  <>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star rating-clr "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product?.rating === 3 ? (
                                  <>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                    <i className="fa-solid font-sm fa-star "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product?.rating === 4 ? (
                                  <>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star rating-clr "></i>
                                    <i className="fa-solid font-sm fa-star  "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product?.rating === 5 ? (
                                  <>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star  rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                    <i className="fa-solid font-sm fa-star rating-clr "></i>
                                    <i className="fa-solid font-sm fa-star rating-clr"></i>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
