import React from "react";
import Home from "./Components/Home/Home";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Shop from "./Components/Shop/Shop";
import ProductDetails from "./Components/ProductDetails/ProductDetails";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Cart from "./Components/Cart/Cart";
import Description from "./Components/Description/Description";
import Reviews from "./Components/Reviews/Reviews";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import CheckOut from "./Components/Checkout/CheckOut";
import Payment from "./Components/Payment/Payment";
import ContactUs from "./Components/ContactUs/ContactUs";
import About from "./Components/About/About";
import Profile from "./Components/Profile/Profile";
import AccountDetails from "./Components/AccountDetails/AccountDetails";
import Orders from "./Components/Orders/Orders";
import Addresses from "./Components/Addresses/Addresses";
import Wishlist from "./Components/WishList/Wishlist";
import TermsAndPolicy from "./Components/TermsAndPolicy/TermsAndPolicy";
import WashingInstructions from "./Components/WashingInstructions/WashingInstructions";
import Refund from "./Components/Refund/Refund";
import Sustainablefashion from "./Components/Sustainablefashion/Sustainablefashion";
import { Toaster } from "react-hot-toast";
import Verify from "./Verify/Verify";
import NotFound from "./Components/NotFound/NotFound";
import CartContextProvider from "./Components/Context/userContext";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import NewPassowrd from "./Components/NewPassowrd/NewPassowrd";
import NearstStore from "./Components/NearstStore/NearstStore";

export default function App() {
  let routers = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: "shop?/:id", element: <Shop /> },
        { path: "productdetails", element: <ProductDetails /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "productdetails/:productId", element: <ProductDetails /> },
        {
          path: "cart",
          element: (
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          ),
        },
        { path: "washinginstructions", element: <WashingInstructions /> },
        { path: "refund", element: <Refund /> },
        { path: "sustainablefashion", element: <Sustainablefashion /> },
        { path: "termsandpolicy", element: <TermsAndPolicy /> },
        {
          path: "checkout",
          element: (
            <ProtectedRoute>
              <CheckOut />
            </ProtectedRoute>
          ),
        },
        {
          path: "Payment",
          element: (
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          ),
        },
        { path: "contactus", element: <ContactUs /> },
        { path: "about", element: <About /> },
        { path: "verify/:id", element: <Verify /> },
        {
          path: "wishlist",
          element: (
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          ),
        },
        {
          path: "profile",
          element: (
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute>
                  <AccountDetails />
                </ProtectedRoute>
              ),
            },
            {
              path: "orders",
              element: (
                <ProtectedRoute>
                  <Orders />
                </ProtectedRoute>
              ),
            },
            {
              path: "address",
              element: (
                <ProtectedRoute>
                  <Addresses />
                </ProtectedRoute>
              ),
            },
          ],
        },
        { path: "*", element: <NotFound /> },
        { path: "forgetpassoword", element: <ForgetPassword /> },
        { path: "newpassword/:id", element: <NewPassowrd /> },
        { path: "nearststore", element: <NearstStore /> },
      ],
    },
  ]);

  return (
    <>
      <CartContextProvider>
        <Toaster />

        <RouterProvider router={routers}></RouterProvider>
      </CartContextProvider>
    </>
  );
}
