import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { isMobile } from "mobile-device-detect";
import { FallingLines } from "react-loader-spinner";
import { CartContext } from "../Context/userContext";
import toast from "react-hot-toast";

export default function Cart() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
  };

  const [promoCode, setPromoCode] = useState(false);
  const [loyaltyCard, setLoyaltyCard] = useState(false);
  const [validPromoCode, setValidPromoCode] = useState(false);
  const [validloyaltyCard, setloyaltyCard] = useState(false);
  const [usingPromoCode, setUsingPromoCode] = useState(false);
  const [showInputPromoCode, setShowInputPromoCode] = useState(false);
  const [showInputLoyaltyCard, setshowInputLoyaltyCard] = useState(false);
  const [promoCodeSaleValue, setPromoCodeSaleValue] = useState(false);

  const [availPromoCode, setAvailPromoCode] = useState(false);
  const [availLoyltyCard, setAvailLoyltyCard] = useState(false);

  const [finalPriceAfter, setFinalPriceAfter] = useState(false);
  const [finalPriceBefore, setFinalPriceBefore] = useState(false);
  const [finalSaleValue, setFinalSaleValue] = useState(false);

  const [loyaltyCardSaleValue, setLoyaltyCardSaleValue] = useState(false);
  const [availLoyalty, setAvailLoyalty] = useState(false);

  async function addPromoCode() {
    axios
      .post(
        `https://kanza-back-end.onrender.com/promocodes/applyPromoCode`,
        {
          code: promoCode,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message);
        setShowInputPromoCode(false);
        setshowInputLoyaltyCard(false);
        getCartProducts();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        localStorage.removeItem("priceAfterPromoCode");
        setShowInputPromoCode(false);
      });
  }
  async function addloyaltyCard() {
    axios
      .patch(
        `https://kanza-back-end.onrender.com/loyaltyCards/assign`,
        {
          code: loyaltyCard,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message);
        setShowInputPromoCode(false);
        setshowInputLoyaltyCard(false);
        getCartProducts();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setShowInputPromoCode(false);
      });
  }

  const handelPromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };
  const handelLoyaltyCardChang = (event) => {
    setLoyaltyCard(event.target.value);
  };

  let { setNumberOfCartItems, numberOfCartItems, getCart } =
    useContext(CartContext);

  async function deleteProduct(productId, varianId) {
    axios
      .delete(
        `https://kanza-back-end.onrender.com/users/removeFromCart/${productId}/${varianId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        // console.log(response);
        getCartProducts2();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }

  async function clearCart() {
    setLoading(true);
    axios
      .delete(`https://kanza-back-end.onrender.com/users/emptyCart`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        // console.log(response);
        getCartProducts();
        setNumberOfCartItems(0);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  }

  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");

  const [cartProducts, setCartProducts] = useState([]);

  let navigate = useNavigate();

  async function getCartProducts() {
    if (localStorage.getItem("token")) {
      setLoading(true);
      let { data } = await getCart();
      setLoading(false);
      setCartProducts(data?.cart);
      setTotalPrice(data?.total);
      setUsingPromoCode(data?.usingPromoCodeTotal);
      setPromoCodeSaleValue(data?.promoCodeSaleValue);
      setAvailPromoCode(data?.usingPromoCode);
      setAvailLoyltyCard(data?.usingLoyaltyCard);
      setLoyaltyCardSaleValue(data?.loyaltyCardSaleValue);
      setAvailLoyalty(data?.usingLoaltyCardTotal);
      setFinalPriceAfter(
        data?.usingLoaltyCardTotal || data?.usingPromoCodeTotal
      );
      localStorage.setItem(
        "priceAfterSale",
        data?.usingLoaltyCardTotal || data?.usingPromoCodeTotal
      );
    } else {
      navigate("/login");
    }
  }
  async function getCartProducts2() {
    if (localStorage.getItem("token")) {
      let { data } = await getCart();
      setNumberOfCartItems(data?.cart?.length);
      setCartProducts(data?.cart);
      setTotalPrice(data?.total);
      // console.log(data);
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    getCartProducts();
  }, []);

  async function updateProduct(productid, varianId, quantity) {
    axios
      .put(
        `https://kanza-back-end.onrender.com/users/updateCart`,
        {
          productId: productid,
          variantId: varianId,
          quantity: quantity,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        // console.log(response);
        getCartProducts2();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }

  useEffect(() => {
    if (availPromoCode && availLoyltyCard) {
      setSelectedOption("option2");
    } else if (availLoyltyCard) {
      setSelectedOption("option2");
    } else if (availPromoCode) {
      setSelectedOption("option1");
    }
  }, [availPromoCode, availLoyltyCard]);

  if (loading === true) {
    return (
      <>
        <div className="loading">
          {isMobile ? (
            <FallingLines
              color="#044F5C"
              width="150"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          ) : (
            <FallingLines
              color="#044F5C"
              width="300"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          )}
        </div>
      </>
    );
  } else if (cartProducts?.length === 0) {
    return (
      <>
        {loading ? (
          <div className="loading">
            {isMobile ? (
              <FallingLines
                color="#044F5C"
                width="150"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            ) : (
              <FallingLines
                color="#044F5C"
                width="300"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            )}
          </div>
        ) : (
          <section>
            <div className="container py-5 mt-md-4">
              <div className="row mt-3">
                <div className="col-md-12 main-cover">
                  <h2 className="cover-text">Cart</h2>
                </div>
                <div className="col-md-12">
                  <div className="d-flex w-75 mx-auto  justify-content-between mt-3">
                    <Link className="text-decoration-none" to={"/cart"}>
                      <p className="text-black">Cart</p>
                    </Link>
                    <Link className="text-decoration-none" to={"/checkout"}>
                      <p className="main-clr-text ps-4">Checkout</p>
                    </Link>
                    <Link className="text-decoration-none">
                      <p className="main-clr-text">Payment</p>
                    </Link>
                  </div>
                  <div className="d-flex w-75 mx-auto justify-content-between mt-1 align-items-center mb-5 pb-5">
                    <div className="cartCircle"></div>
                    <div className="cartLine"></div>
                    <div className="cartCircle2"></div>
                    <div className="cartLine"></div>
                    <div className="cartCircle3"></div>
                  </div>

                  <h1 className="text-center my-5 main-clr-text2 py-5">
                    Your Cart Is Empty
                  </h1>

                  {/* <div className='d-flex mt-5 pt-4 justify-content-between'>
                      <h6>Total Price</h6>
                      <p>{totalPrice} EGP</p>
                  </div> */}

                  {/* <div className='text-end mt-3'>
                      <Link to={'/checkout'}><button className='btn main-clr text-white px-5 py-1'>Next</button>
                      </Link>

                  </div> */}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }

  return (
    <>
      <section>
        <div className="container py-5 mt-md-4">
          <div className="row mt-3">
            <div className="col-md-12 main-cover">
              <h2 className="cover-text">Cart</h2>
            </div>
            <div className="col-md-12 ">
              <div className="d-flex w-75 mx-auto  justify-content-between mt-3">
                <Link className="text-decoration-none" to={"/cart"}>
                  <p className="text-black">Cart</p>
                </Link>
                <Link className="text-decoration-none" to={"/checkout"}>
                  <p className="main-clr-text ps-4">Checkout</p>
                </Link>
                <Link className="text-decoration-none">
                  <p className="main-clr-text">Payment</p>
                </Link>
              </div>
              <div className="d-flex w-75 mx-auto justify-content-between mt-1 align-items-center">
                <div className="cartCircle"></div>

                <div className="cartLine"></div>
                <Link className="text-decoration-none" to={"/checkout"}>
                  <div className="cartCircle2"></div>
                </Link>
                <div className="cartLine"></div>
                <div className="cartCircle3"></div>
              </div>
              {cartProducts?.map((product, index) => (
                <div key={index} className="products mt-5">
                  <div className="d-flex justify-content-between">
                    <Link
                      to={`/productdetails/${product?.product._id}`}
                      className="text-decoration-none text-dark"
                    >
                      <div className="d-flex">
                        <img
                          src={product?.product?.images[0]}
                          style={{ minWidth: "120px", maxWidth: "120px" }}
                          className="rounded-3 h-100"
                          alt={product?.product?.name}
                        />
                        <div className="ms-3">
                          <h6>{product?.product?.name}</h6>
                          <div className="d-flex flex-column h-100 justify-content-between">
                            <p>Size: {product?.product?.variant?.size}</p>

                            {product?.product?.variant?.isSale ? (
                              <>
                                {isMobile ? (
                                  <div className="w-100 mb-5">
                                    <div className="d-flex flex-column align-items-start w-50">
                                      <p className="m-0 p-0">
                                        {product?.product?.variant?.price.toLocaleString()}{" "}
                                        EGP
                                      </p>
                                      <p className="text-decoration-line-through text-muted m-0 p-0">
                                        {product?.product?.variant?.price +
                                          product?.product?.variant?.saleValue}
                                        EGP
                                      </p>
                                      <div className="rounded-4 px-2 py-0 bg-danger text-white d-flex justify-content-center align-items-center font-sm mb-4 fw-bold py-1 ">
                                        Sale{" "}
                                        {
                                          product?.product?.variant
                                            ?.salePercentage
                                        }{" "}
                                        %
                                      </div>
                                    </div>
                                    {/* <div className="hurryUp d-flex justify-content-between align-items-center py-1 w-100">
                  <h6 className=" fs-5 huryupText fw-bold">
                    Hurry up! Sale ends in:
                  </h6>
                  <p className="me-3 fw-bold huryupcounter">
                    {days} D : {hours} H : {minutes} M : {seconds} S
                  </p>
                </div> */}
                                  </div>
                                ) : (
                                  <div className="w-100 mb-5">
                                    <div className="d-flex w-100">
                                      <p className="me-3">
                                        {product?.product?.variant?.price} EGP
                                      </p>
                                      <p className="text-decoration-line-through text-muted">
                                        {product?.product?.variant?.price +
                                          product?.product?.variant?.saleValue}
                                        EGP
                                      </p>
                                      <div className="rounded-4 ms-2 px-2 py-0 bg-danger text-white d-flex justify-content-center align-items-center font-sm mb-3 fw-bold py-1 ">
                                        Sale{" "}
                                        {
                                          product?.product?.variant
                                            ?.salePercentage
                                        }{" "}
                                        %
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {" "}
                                <p className="mb-5">
                                  {product?.product?.variant?.price.toLocaleString()}{" "}
                                  EGP
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className=" d-flex flex-column align-items-center justify-content-between">
                      <i
                        onClick={() => {
                          deleteProduct(
                            product?.product._id,
                            product?.product?.variant?._id
                          );
                        }}
                        className="fa-solid fa-trash main-clr-text2 fs-5 cursor-pointer"
                      ></i>
                      <div className="cartIncDec mb-2 ps-1">
                        <span
                          className="px-2 cursor-pointer"
                          onClick={() => {
                            updateProduct(
                              product?.product._id,
                              product?.product?.variant?._id,
                              product?.quantity - 1
                            );
                          }}
                        >
                          -
                        </span>
                        <span className="px-2">{product?.quantity}</span>
                        <span
                          className="px-2 cursor-pointer"
                          onClick={() => {
                            updateProduct(
                              product?.product._id,
                              product?.product?.variant?._id,
                              product?.quantity + 1
                            );
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {isMobile ? (
                <div className=" w-100 d-flex justify-content-center">
                  {availPromoCode || availLoyltyCard ? (
                    <>
                      <div className="d-flex  flex-column w-100 mt-5 pt-4">
                        <div className="w-100 d-flex justify-content-between">
                          <h6 className="fw-bold fs-6 ">Total Price Before</h6>
                          <p className="fw-bold fs-6">
                            {finalPriceBefore
                              ? finalPriceBefore.toLocaleString()
                              : totalPrice?.toLocaleString()}{" "}
                            EGP
                          </p>
                        </div>
                        <div className="w-100 d-flex justify-content-between">
                          <h6 className="fw-bold fs-6">Total Price After</h6>
                          <p className="fw-bold fs-6">
                            {finalPriceAfter
                              ? Math.round(finalPriceAfter).toLocaleString()
                              : Math.round(usingPromoCode).toLocaleString() ||
                                Math.round(availLoyalty).toLocaleString()}{" "}
                            EGP
                          </p>
                        </div>
                        <div className="w-100 d-flex justify-content-between">
                          <h6 className="fw-bold fs-6">Sale Value</h6>
                          <p className="fw-bold fs-6">
                            {finalSaleValue
                              ? finalSaleValue
                              : loyaltyCardSaleValue || promoCodeSaleValue}{" "}
                            %
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex totalPriceDiv w-25 mt-5 pt-4 px-2 justify-content-between">
                      <h6 className="fw-bold fs-6">Total Price</h6>
                      <p className="fw-bold fs-6">
                        {totalPrice.toLocaleString()} EGP
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className=" w-100 d-flex justify-content-center">
                  {availPromoCode || availLoyltyCard ? (
                    <>
                      <div className="d-flex totalPriceDiv flex-column w-25 mt-5 pt-4">
                        <div className="w-100 d-flex justify-content-between">
                          <h6 className="fw-bold fs-5 ">Total Price Before</h6>
                          <p className="fw-bold fs-5">
                            {finalPriceBefore
                              ? finalPriceBefore.toLocaleString()
                              : totalPrice?.toLocaleString()}{" "}
                            EGP
                          </p>
                        </div>
                        <div className="w-100 d-flex justify-content-between">
                          <h6 className="fw-bold fs-5">Total Price After</h6>
                          <p className="fw-bold fs-5">
                            {finalPriceAfter
                              ? Math.round(finalPriceAfter).toLocaleString()
                              : Math.round(usingPromoCode).toLocaleString() ||
                                Math.round(availLoyalty).toLocaleString()}{" "}
                            EGP
                          </p>
                        </div>
                        <div className="w-100 d-flex justify-content-between">
                          <h6 className="fw-bold fs-5">Sale Value</h6>
                          <p className="fw-bold fs-5">
                            {finalSaleValue
                              ? finalSaleValue
                              : loyaltyCardSaleValue || promoCodeSaleValue}{" "}
                            %
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex totalPriceDiv w-25 mt-5 pt-4 px-2 justify-content-between">
                      <h6 className="fw-bold fs-5">Total Price</h6>
                      <p className="fw-bold fs-5">
                        {totalPrice.toLocaleString()} EGP
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* <div className='d-flex justify-content-end w-100'>
                          {availPromoCode || availPromoCode && availLoyltyCard ? <>
                          <div className='d-flex justify-content-end w-100'>
                            <label htmlFor="promoCode" className='fw-bold me-2 w-100' >Promo Code</label>
                          <input type="radio" id='promoCode' value="option1" className='me-3 ' checked={selectedOption === 'option1'}
                           onChange={handleOptionChange} onClick={()=>{
                            setFinalSaleValue(promoCodeSaleValue)
                            setFinalPriceBefore(totalPrice?.toLocaleString())
                            setFinalPriceAfter(usingPromoCode?.toLocaleString())
                           }} />

                          </div>
                          </> : <>
                          <div className='d-flex justify-content-end mb-3 w-25'>
                        {showInputPromoCode ? <input  type="text" placeholder='Add Promo Code' className='form-control ps-3' onChange={handelPromoCodeChange} /> : ""}  
                         {showInputPromoCode ?  <button className='btn main-clr text-white px-4 py-2 ms-2' onClick={()=>{
                            addPromoCode()
                          }}>Submit</button> :  <button className='btn main-clr text-white px-4 py-2 ms-2' onClick={()=>{
                            setShowInputPromoCode(true)
                            setshowInputLoyaltyCard(false)
                          }}>Add promo Code</button>}
                          </div>
                          
                          </>}

                          
                          {availLoyltyCard || availLoyltyCard && availPromoCode ? <>
                          <div className='d-flex justify-content-end w-100'>
                            <label htmlFor="laoyltyCard" className='fw-bold me-2'>Loyalty Card</label>
                          <input type="radio" id='laoyltyCard' value="option2" checked={selectedOption === 'option2'}
                           onChange={handleOptionChange} onClick={()=>{
                            setFinalSaleValue(loyaltyCardSaleValue)
                            setFinalPriceBefore(totalPrice?.toLocaleString())
                            setFinalPriceAfter(availLoyalty?.toLocaleString())
                           }}/>

                          </div>
                          </> : <div className='d-flex justify-content-end mb-3 w-25'>
                         {showInputLoyaltyCard? <input type="text" placeholder='Add Loyalty Card' className='form-control ps-3 ms-2' onChange={handelLoyaltyCardChang} /> : "" } 
                         {showInputLoyaltyCard ? <button className='btn main-clr text-white px-4 py-2 ms-2' onClick={()=>{
                            setshowInputLoyaltyCard(true)
                            addloyaltyCard()
                          }}>Submit</button> : <button className='btn main-clr text-white px-4 py-2 ms-2' onClick={()=>{
                            setshowInputLoyaltyCard(true)
                            setShowInputPromoCode(false)
                          }}>Add Loyalty Card</button> }
                          
                          </div>
 }





                          </div> */}

              <div className="d-flex justify-content-end align-items-center">
                {availPromoCode || (availPromoCode && availLoyltyCard) ? (
                  <>
                    <div className="d-flex justify-content-end">
                      <label htmlFor="promoCode" className="fw-bold me-2">
                        Promo Code
                      </label>
                      <input
                        type="radio"
                        id="promoCode"
                        value="option1"
                        className="me-3"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                        onClick={() => {
                          setFinalSaleValue(promoCodeSaleValue);
                          setFinalPriceBefore(totalPrice);
                          setFinalPriceAfter(usingPromoCode);
                          localStorage.setItem("priceAftersale", "promo code");
                          localStorage.setItem(
                            "priceAfterSale",
                            usingPromoCode
                          );
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-end mb-3 align-items-center align-items-center">
                    {showInputPromoCode ? (
                      <input
                        type="text"
                        placeholder="Add Promo Code"
                        className="form-control ps-3"
                        onChange={handelPromoCodeChange}
                      />
                    ) : (
                      ""
                    )}
                    {showInputPromoCode ? (
                      <button
                        className="btn main-clr text-white px-4 py-2 ms-2"
                        onClick={() => {
                          addPromoCode();
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="btn main-clr text-white px-4 py-2 ms-2"
                        onClick={() => {
                          setShowInputPromoCode(true);
                          setshowInputLoyaltyCard(false);
                        }}
                      >
                        Add promo Code
                      </button>
                    )}
                  </div>
                )}
                {availLoyltyCard || (availLoyltyCard && availPromoCode) ? (
                  <>
                    <div className="d-flex justify-content-end align-items-center ms-3">
                      <label htmlFor="laoyltyCard" className="fw-bold me-2">
                        Loyalty Card
                      </label>
                      <input
                        type="radio"
                        id="laoyltyCard"
                        value="option2"
                        checked={selectedOption === "option2"}
                        onChange={handleOptionChange}
                        onClick={() => {
                          setFinalSaleValue(loyaltyCardSaleValue);
                          setFinalPriceBefore(totalPrice);
                          setFinalPriceAfter(availLoyalty);
                          localStorage.setItem(
                            "priceAftersale",
                            "loyalty card"
                          );
                          localStorage.setItem("priceAfterSale", availLoyalty);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-end mb-3 ">
                    {showInputLoyaltyCard ? (
                      <input
                        type="text"
                        placeholder="Add Loyalty Card"
                        className="form-control ps-3 ms-2"
                        onChange={handelLoyaltyCardChang}
                      />
                    ) : (
                      ""
                    )}
                    {showInputLoyaltyCard ? (
                      <button
                        className="btn main-clr text-white px-4 py-2 ms-2"
                        onClick={() => {
                          setshowInputLoyaltyCard(true);
                          addloyaltyCard();
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="btn main-clr text-white px-4 py-2 ms-2"
                        onClick={() => {
                          setshowInputLoyaltyCard(true);
                          setShowInputPromoCode(false);
                        }}
                      >
                        Add Loyalty Card
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className="text-end mt-3">
                <button
                  onClick={clearCart}
                  className="btn main-clr text-white px-3 py-1 me-2"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <Link to={"/checkout"}>
                  <button className="btn main-clr text-white px-3 py-1">
                    <i className="fa-solid fa-chevron-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
