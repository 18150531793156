import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import test from '../../Assets/Images/clothes1.png'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';
import { Mousewheel, Pagination , Navigation } from 'swiper/modules';
import circleImg from '../../Assets/Icons/Philadelphia_Branding_Photography_0001-1024x751 1.png'
import { isMobile } from 'mobile-device-detect';
import axios from 'axios';
import { Link } from 'react-router-dom';
export default function ReviewsSlider() {

    const [reviews, setReviews] = useState([])

    async function getReviews() {
        let {data} = await axios.get(`https://kanza-back-end.onrender.com/reviews/random`)
        // console.log(data.reviews);
        setReviews(data.reviews)
    }

    useEffect(()=>{
        getReviews()
    },[])


    if (reviews.length === 0) {
      return 
    }
    else{
      return<>
      {isMobile ?<section className='review-slider container'>
                  <h3 className='text-center py-3'>Our Customer’s Review</h3>
              <div className='pb-5 main-color-section'>
              <div>
              <Swiper 
                  slidesPerView={1}
                  spaceBetween={45}
                  mousewheel={true}
                  navigation={true}
  
                  modules={[Mousewheel, Pagination , Navigation ]}
                  className="mySwiper1 pe-5 ">
                  {reviews.map(review=> <SwiperSlide className='mt-4 pt-2 pb-0 px-4 mx-4 div-test'>
                      <div className="row"> 
                       <div className='text-start'>
                    {review.rating === 1 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </> : '' }
                    {review.rating === 2 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </> : '' }
                    {review.rating === 3 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </> : '' }
                    {review.rating === 4 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr "></i>
                      <i className="fa-solid fa-star  "></i>
                    </> : '' }
                    {review.rating === 5 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr "></i>
                      <i className="fa-solid fa-star rating-clr"></i>
                    </> : '' }
                      </div>
                      <p className='text-start font-sm'>{review.review}</p>
                      <div className='d-flex align-items-center'>
                      <img src={circleImg} className='circleImg me-2 pb-0' alt="circleImg" />
                      <h6 >Monica Nady</h6>
                      </div>
                      </div>  
                          
                 
                    
                    
                      
                  </SwiperSlide>)}
             
                 
             
              </Swiper>
              </div>
              </div> 
              </section> : <section className='review-slider container'>
                  <h3 className='text-center py-3'>Our Customer’s Review</h3>
              <div className=' pb-5  main-color-section'>
              <div>
              <Swiper 
                  slidesPerView={3}
                  spaceBetween={50}
                  mousewheel={true}
  
                  // pagination={{
                  //     clickable: true,
                  // }}
                  navigation={true}
  
                  modules={[Mousewheel, Pagination , Navigation ]}
                  className="mySwiper1  container px-5">
                      {reviews.map(review =>  <SwiperSlide className='mt-4 p-3 mx-2 pt-4 rounded-4  review-slider-div'>
                      <div className="row"> 
                      <div className='d-flex  justify-content-between align-items-center '>
                        
                      <div className='d-flex align-items-center'>
                      <img src={review.user.image} className='circleImg rounded-circle me-2 pb-md-0 pb-3' alt="circleImg" />
                      <h6 >{review.user.firstName + " " + review.user.lastName}</h6>
                      </div>
                      <div className='fs-6'>
                       {review.rating === 1 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </> : '' }
                    {review.rating === 2 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </> : '' }
                    {review.rating === 3 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </> : '' }
                    {review.rating === 4 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr "></i>
                      <i className="fa-solid fa-star  "></i>
                    </> : '' }
                    {review.rating === 5 ? <>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star  rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr"></i>
                      <i className="fa-solid fa-star rating-clr "></i>
                      <i className="fa-solid fa-star rating-clr"></i>
                    </> : '' }
                      </div>
                      </div>
                     
                      
                     <Link className='text-decoration-none text-black' to={`/productdetails/${review.product}`}>
                     <p className='text-start mt-2  font-md'>{review.review}</p>
                     </Link>

                      </div>  

                  </SwiperSlide>)}
                  
                
          
             
              </Swiper>
              {/* <SwiperNavButtons/> */}
              </div>
              </div> 
              </section> }
              
            
          </>
    }


  
}
