import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";
import { isMobile } from "mobile-device-detect";
import { FallingLines } from "react-loader-spinner";

export default function HomeBanner() {
  const [loading, setLoading] = useState(false);

  const [banners, setBanners] = useState([]);
  async function getbanners() {
    setLoading(true);
    let { data } = await axios.get("https://kanza-back-end.onrender.com/cover");
    // console.log(data);
    setBanners(data);
    setLoading(false);
  }

  useEffect(() => {
    getbanners();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading">
          {isMobile ? (
            <FallingLines
              color="#044F5C"
              width="150"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          ) : (
            <FallingLines
              color="#044F5C"
              width="300"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          )}
        </div>
      ) : (
        <section className="container h-100  mt-md-4 mt-3 pt-md-5 pt-4 pb-md-5 pb-3">
          <Swiper
            style={{ width: "100%" }}
            spaceBetween={0}
            autoplay={true}
            speed={100}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {banners.map((banner) => (
              <SwiperSlide>
                {isMobile ? (
                  <img
                    src={banner.image}
                    style={{
                      minHeight: "230px",
                      maxHeight: "280px",
                      width: "400px",
                    }}
                    alt="banner"
                  />
                ) : (
                  <img
                    src={banner.image}
                    style={{ minHeight: "493px", maxHeight: "493px" }}
                    alt="banner"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )}
    </>
  );
}
