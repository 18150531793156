import React from 'react'
import refund from '../../Assets/Icons/refund.png'
import sustainable from '../../Assets/Icons/sustainable.png'
import washing from '../../Assets/Icons/washing.png'
import nearst from '../../Assets/Icons/nearst.png'
import { Link } from 'react-router-dom'
export default function ServicesAndPayment() {
  return <>
  <section className='container py-5 '>
    <div className="row">
        <div className="col-lg-3 mb-md-0 mb-3 cursor-pointer ">
            <Link className='text-decoration-none text-black' to={'/washinginstructions'}>
            <div className='shape1 px-4 py-3'>
            <div className='text-center pb-4 mt-3 pt-2 bg-white pt-md-5 pt-3'>
                <img src={washing} alt="washing" />
                <h3 className='pt-3'>Washing Instructions </h3>
            </div>
            </div>
            </Link>
            
          
        </div>
        <div className="col-lg-6">
    
            <div className="row">
                
                <div className="col-md-12 shape2 pb-3 cursor-pointer">
                <Link to={'/refund'} className='text-decoration-none text-black'>
                <div  className='d-flex justify-content-center align-items-center bg-white mt-4 flex-lg-row flex-column text-center py-3'>
                            <img src={refund} alt="refund" className='me-3 ms-3 pt-md-0 pt-2'/>
                            <div className='pt-3'>
                                <h3>Refund & Exchange Policies</h3>
                            </div>
                    </div>
            </Link>
    
                </div>
                <div className="col-md-12 shape2 pb-3 mt-md-1 mt-3 mb-md-0 mb-3 cursor-pointer">
                    <Link className='text-decoration-none text-black' to={'/sustainablefashion'}>
                    <div  className='d-flex justify-content-center align-items-center bg-white mt-4 flex-lg-row flex-column text-center py-3'>
                            <img src={sustainable} alt="sustainable" className='me-md-3 pe-md-3 pt-md-0 pt-2'/>
                            <div className='pt-3'>
                                <h3>Sustainable Fashion</h3>
                            </div>
                    </div>
                    </Link>
           
                </div>
            </div>
        </div>
        <div className="col-lg-3 cursor-pointer">
        <Link className='text-decoration-none text-black' to={'/nearststore'}>
            <div className='shape1 px-4 py-3'>
            <div className='text-center pb-md-5 pb-3 mt-3 pt-2 bg-white pt-md-5 pt-3'>
                <img src={nearst} className='pt-2' alt="nearst" />
                <h3 className='pt-3'>Your Nearst store</h3>
            </div>
            </div>
            </Link>
          
        </div>
    </div>
  </section>
  
  </>
}
