import React, { useContext, useEffect, useState } from "react";
import starIcon from "../../Assets/Icons/star.png";
import Dropdown from "react-bootstrap/Dropdown";
import productButton from "../../Assets/Icons/span.f-badge.png";
import { Link, useHref, useParams } from "react-router-dom";
import { isMobile, isTablet } from "mobile-device-detect";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import {
  BallTriangle,
  FallingLines,
  MutatingDots,
  Vortex,
} from "react-loader-spinner";
import toast from "react-hot-toast";
import { CartContext } from "../Context/userContext";
import Slider from "@mui/material/Slider";
import "rc-slider/assets/index.css";
import "rc-slider/assets/index.css";

export default function Products() {
  let href = useHref();
  let { id } = useParams();

  if (href === "/shop") {
    localStorage.removeItem("categoryId");
  }

  const [loadingProdutcs, setLoadingProdutcs] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [value, setValue] = useState([0, 20000]);
  const [price, setPrice] = useState({});
  const [priceValue, setPriceValue] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(1000);

  const [products, setProducts] = useState([]);

  const sortedProducts = products.map(product => {
    const sortedVariants = [...product.variants].sort((a, b) => {
      if (a.isAvailable && !b.isAvailable) return -1;
      if (!a.isAvailable && b.isAvailable) return 1;
      return 0;
    });

  return {
    ...product,
    variants: sortedVariants
  };
});



  async function addToWishList(productId, varianId) {
    setLoading(true);
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToWishlist`,
        {
          productId: productId,
          variantId: varianId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        filter(currentPage, itemsPerPage);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
    setLoading(false);
  }

  async function getproductsOfCategories(id, page = 1, perPage = 8) {
    axios
      .get(
        `https://kanza-back-end.onrender.com/products/all?category[]=${id}`,
        {
          params: {
            page: page,
            perPage: perPage,
          },
        }
      )
      .then((response) => {
        setTotalPages(response?.data?.pages || 1);
        setProducts(response?.data.products);
        // setPrice(response?.data);
        setTestCategoryNav(false);
        settestcategory2(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let {
    setNumberOfCartItems,
    numberOfCartItems,
    testcategoryNav,
    setTestCategoryNav,
    testcategory2,
    settestcategory2,
  } = useContext(CartContext);

  if (testcategoryNav === true) {
    getproductsOfCategories(localStorage.getItem("categoryId"));
  }

  async function addToCart(productId, varianId) {
    setLoading(true);

    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToCart`,
        {
          productId: productId,
          variantId: varianId,
          quantity: 1,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setNumberOfCartItems(response?.data?.cart?.products?.length);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  }

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  // async function getproducts(page = 1, perPage = 8) {
  //   setLoading(true);
  //   if (localStorage.getItem("token")) {
  //     setLoading(true);
  //     let { data } = await axios.get(
  //       `https://kanza-back-end.onrender.com/products/all`,
  //       {
  //         params: {
  //           page: page,
  //           perPage: perPage,
  //         },
  //         headers: {
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     setTotalPages(data?.pages || 1);
  //     setProducts(data.products);
  //     setPrice(data);
  //     // console.log(data);
  //     setLoading(false);
  //   } else {
  //     setLoading(true);
  //     let { data } = await axios.get(
  //       `https://kanza-back-end.onrender.com/products/all`,
  //       {
  //         params: {
  //           page: page,
  //           perPage: perPage,
  //         },
  //       }
  //     );
  //     setTotalPages(data?.pages || 1);
  //     setProducts(data.products);
  //     setLoading(false);
  //     setPrice(data);
  //   }
  // }
  // async function getproducts2(page = 1, perPage = 8) {
  //   if (localStorage.getItem("token")) {
  //     let { data } = await axios.get(
  //       `https://kanza-back-end.onrender.com/products/all`,
  //       {
  //         params: {
  //           page: page,
  //           perPage: perPage,
  //         },
  //         headers: {
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     setProduct(data.products);
  //     setTotalPages(data?.pages || 1);
  //   } else {
  //     let { data } = await axios.get(
  //       `https://kanza-back-end.onrender.com/products/all`
  //     );
  //     setProduct(data.products);
  //     setTotalPages(data?.pages || 1);
  //   }
  // }

  useEffect(() => {
    localStorage.getItem("categoryId")
      ? getproductsOfCategories(
          localStorage.getItem("categoryId"),
          currentPage,
          itemsPerPage
        )
      : filter(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);
  const handleSizeChange = (size) => {
    if (selectedSizes.includes(size)) {
      setSelectedSizes(selectedSizes.filter((s) => s !== size));
    } else {
      setSelectedSizes([...selectedSizes, size]);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPriceValue(newValue);
  };
  const valuetext = (value) => {
    return `$${Math.floor(value)}`;
  };
  useEffect((_) => {
    axios
      .get("https://kanza-back-end.onrender.com/products/categories", {
        params: {},
      })
      .then((res) => {
        setCategories(res.data?.categories);
      })
      .catch((err) => console.error(err.response?.data?.message));
    axios
      .get("https://kanza-back-end.onrender.com/products/subcategories")
      .then((res) => {
        setSubCategories(res.data);
      })
      .catch((err) => console.error(err.response?.data?.message));
  }, []);
  const filter = (page = 1, perPage = 8) => {
    let tempUrl = `https://kanza-back-end.onrender.com/products/all?`;
    let setSelectedCategories = new Set(selectedCategories);

    Array.from(setSelectedCategories).map((category, index) => {
      tempUrl += `&category[${index}]=${category}`;
    });

    let setSelectedSubCategories = new Set(selectedSubCategories);
    Array.from(setSelectedSubCategories).map((subCategory, index) => {
      tempUrl += `&subcategory[${index}]=${subCategory}`;
    });
    tempUrl += `&priceFrom=${priceValue[0] || 0}&priceTo=${
      priceValue[1] || 200000
    }`;
    selectedSizes.forEach((weight, index) => {
      tempUrl += `&size[${index}]=${weight}`;
    });

    axios
      .get(tempUrl, {
        params: {
          page: page,
          perPage: perPage,
        },
      })
      .then((res) => {
        setTotalPages(res.data?.pages || 1);
        setProducts(res.data?.products);
        document.getElementById("test500").click();
      })
      .catch((err) => {
        console.error(err.response?.data?.message);
      })
      .finally((_) => {});
  };

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setPriceValue([0, 200000]);
    setSelectedSizes([]);
    filter(1, 8);
    localStorage.removeItem("categoryId");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <>
      <section className="overflow-hidden mt-md-5 mt-5 py-5 w-100">
        <div className="d-flex align-items-start mx-5 px-5 ms-md-3 ms-0 flex-md-row flex-column w-100">
          {isMobile || isTablet ? (
            <section className="w-100 fillters">
              <button
                className="btn main-clr text-white filterbutton rounded-5 mb-0 mt-0 position-fixed z-3 py-2 px-3 mt-1"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                Filters <i className="fa-solid fa-filter"></i>
              </button>

              <div
                className="offcanvas offcanvas-bottom overflow-hidden h-50"
                tabindex="-1"
                id="offcanvasBottom"
                aria-labelledby="offcanvasBottomLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasBottomLabel">
                    filter Products
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Opne"
                    id="test500"
                  ></button>
                </div>
                <div className="offcanvas-body small">
                  <div className="row w-25 filterProducts mt-md-3 mt-0 fillters">
                    <div
                      className="accordion mt-md-5 mt-3 pt-3"
                      id="accordionExample"
                    >
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <h4 className="h5 pb-0 mb-0">Categories</h4>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className={
                            isMobile
                              ? "accordion-collapse collapse"
                              : "accordion-collapse collapse show"
                          }
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row ">
                              {!loading &&
                                categories &&
                                Array.isArray(categories) &&
                                categories.length > 0 &&
                                categories.map((one, index) => (
                                  <div
                                    className="d-flex align-items-center "
                                    key={one._id}
                                  >
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        // id="Dresses"
                                        id={`cbx-${40 + index}`}
                                        className="inp-cbx custom-checkbox"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            let t = [...selectedCategories];
                                            t.push(one._id);
                                            setSelectedCategories([...t]);
                                          } else {
                                            selectedCategories.map(
                                              (oneSelected, selectedIndex) => {
                                                if (
                                                  oneSelected._id == one._id
                                                ) {
                                                  let t = [
                                                    ...selectedCategories,
                                                  ];
                                                  t.splice(selectedIndex, 1);
                                                  setSelectedCategories([...t]);
                                                }
                                              }
                                            );
                                          }
                                          // console.log(selectedCategories);
                                          // console.log(
                                          //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                          // )
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      <label
                                        htmlFor={`cbx-${40 + index}`}
                                        className="me-5 pe-3 cursor-pointer"
                                      >
                                        {one.name}
                                      </label>
                                      <br />
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <h4 className="h5 pb-0 mb-0">Sub Categories</h4>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                {!loading &&
                                  subCategories &&
                                  Array.isArray(subCategories) &&
                                  subCategories.length > 0 &&
                                  subCategories.map((one, index) => (
                                    <>
                                      <div
                                        className="d-flex align-items-center"
                                        key={one._id}
                                      >
                                        <label className="checkbox-container">
                                          <input
                                            className="custom-checkbox inp-cbx"
                                            type="checkbox"
                                            id={`cbx-${100 + index}`}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                let t = [
                                                  ...selectedSubCategories,
                                                ];
                                                t.push(one._id);
                                                setSelectedSubCategories([
                                                  ...t,
                                                ]);
                                              } else {
                                                selectedSubCategories.map(
                                                  (
                                                    oneSelected,
                                                    selectedIndex
                                                  ) => {
                                                    if (
                                                      oneSelected._id == one._id
                                                    ) {
                                                      let t = [
                                                        ...selectedSubCategories,
                                                      ];
                                                      t.splice(
                                                        selectedIndex,
                                                        1
                                                      );
                                                      setSelectedSubCategories([
                                                        ...t,
                                                      ]);
                                                    }
                                                  }
                                                );
                                              }
                                              // console.log(
                                              //   selectedSubCategories
                                              // );
                                              // console.log(
                                              //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                              // )
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <label
                                            htmlFor={`cbx-${100 + index}`}
                                            className="me-5 pe-4 cursor-pointer"
                                          >
                                            {one.name}
                                          </label>
                                        </label>
                                      </div>
                                      <br />
                                    </>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <h4 className="h5 mb-0 pb-0">Size</h4>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-flex align-items-center">
                                  <label className="checkbox-container">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id="xs"
                                      onChange={() => handleSizeChange("XS")}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor="xs"
                                      className="me-5 pe-4 cursor-pointer"
                                    >
                                      XS
                                    </label>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="checkbox-container">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id="s"
                                      onChange={() => handleSizeChange("S")}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor="s"
                                      className="me-5 pe-4 cursor-pointer"
                                    >
                                      S
                                    </label>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="checkbox-container">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id="m"
                                      onChange={() => handleSizeChange("M")}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor="m"
                                      className="me-5 pe-4 cursor-pointer"
                                    >
                                      M
                                    </label>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="checkbox-container w-100">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id="l"
                                      onChange={() => handleSizeChange("L")}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor="l"
                                      className="me-5 pe-1 cursor-pointer"
                                    >
                                      L
                                    </label>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="checkbox-container w-100">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id="xl"
                                      onChange={() => handleSizeChange("XL")}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor="xl"
                                      className="me-5 pe-1 cursor-pointer"
                                    >
                                      XL
                                    </label>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="checkbox-container w-100">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id="2xl"
                                      onChange={() => handleSizeChange("2XL")}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor="2xl"
                                      className="me-5 pe-1 cursor-pointer"
                                    >
                                      2XL
                                    </label>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <h4 className="h5 pb-0 mb-0">Price</h4>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-8 w-100">
                                <div className="d-flex justify-content-between w-100">
                                  <p>Range</p>
                                  {/* <p>10 EGP - 500 EGP</p> */}
                                </div>
                                <div className="card-conteiner">
                                  <div className="range-slider">
                                    <Slider
                                      getAriaLabel={() => "Price range"}
                                      value={value}
                                      onChange={handleChange}
                                      valueLabelDisplay="auto"
                                      getAriaValueText={valuetext}
                                      min={price.lowestPrice || 0}
                                      max={price.highestPrice || 500}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                      }}
                                    >
                                      {" "}
                                      {priceValue.length > 0 && (
                                        <span style={{ fontSize: "14px" }}>
                                          {priceValue[0]} EGP - {priceValue[1]}{" "}
                                          EGP
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            onClick={resetFilters}
                            className="btn main-clr text-white me-3"
                          >
                            Reset
                          </button>
                          <button
                            className=" btn main-clr text-white my-3 w-50"
                            onClick={() => filter(currentPage, itemsPerPage)}
                          >
                            filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className="row w-25 filterProducts mt-md-3 mt-0 fillters">
              <div
                className="accordion mt-md-5 mt-3 pt-3"
                id="accordionExample"
              >
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h4 className="h5 pb-0 mb-0">Categories</h4>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={
                      isMobile
                        ? "accordion-collapse collapse"
                        : "accordion-collapse collapse show"
                    }
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row ">
                        {!loading &&
                          categories &&
                          Array.isArray(categories) &&
                          categories.length > 0 &&
                          categories.map((one, index) => (
                            <div
                              className="d-flex align-items-center "
                              key={one._id}
                            >
                              <label className="checkbox-container">
                                {localStorage.getItem("categoryId") ===
                                one._id ? (
                                  <input
                                    checked={
                                      localStorage.getItem("categoryId") ===
                                      one._id
                                    }
                                    type="checkbox"
                                    // id="Dresses"
                                    onClick={() => {
                                      localStorage.removeItem("categoryId");
                                    }}
                                    id={`cbx-${40 + index}`}
                                    className="inp-cbx custom-checkbox"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        let t = [...selectedCategories];
                                        t.push(one._id);
                                        setSelectedCategories([...t]);
                                      } else {
                                        selectedCategories.map(
                                          (oneSelected, selectedIndex) => {
                                            if (oneSelected._id == one._id) {
                                              let t = [...selectedCategories];
                                              t.splice(selectedIndex, 1);
                                              setSelectedCategories([...t]);
                                            }
                                          }
                                        );
                                      }
                                      // console.log(selectedCategories);
                                    }}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    id={`cbx-${40 + index}`}
                                    className="inp-cbx custom-checkbox"
                                    onClick={() => {
                                      localStorage.removeItem("categoryId");
                                    }}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        let t = [...selectedCategories];
                                        t.push(one._id);
                                        setSelectedCategories([...t]);
                                      } else {
                                        selectedCategories.map(
                                          (oneSelected, selectedIndex) => {
                                            if (oneSelected._id == one._id) {
                                              let t = [...selectedCategories];
                                              t.splice(selectedIndex, 1);
                                              setSelectedCategories([...t]);
                                            }
                                          }
                                        );
                                      }
                                      // console.log(selectedCategories);
                                      // console.log(
                                      //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                      // )
                                    }}
                                  />
                                )}
                                <span className="checkmark"></span>
                                <label
                                  htmlFor={`cbx-${40 + index}`}
                                  className="me-5 pe-3 cursor-pointer"
                                >
                                  {one.name}
                                </label>
                                <br />
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <h4 className="h5 pb-0 mb-0">Sub Categories</h4>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          {!loading &&
                            subCategories &&
                            Array.isArray(subCategories) &&
                            subCategories.length > 0 &&
                            subCategories.map((one, index) => (
                              <>
                                <div
                                  className="d-flex align-items-center"
                                  key={one._id}
                                >
                                  <label className="checkbox-container">
                                    <input
                                      className="custom-checkbox inp-cbx"
                                      type="checkbox"
                                      id={`cbx-${100 + index}`}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          let t = [...selectedSubCategories];
                                          t.push(one._id);
                                          setSelectedSubCategories([...t]);
                                        } else {
                                          selectedSubCategories.map(
                                            (oneSelected, selectedIndex) => {
                                              if (oneSelected._id == one._id) {
                                                let t = [
                                                  ...selectedSubCategories,
                                                ];
                                                t.splice(selectedIndex, 1);
                                                setSelectedSubCategories([
                                                  ...t,
                                                ]);
                                              }
                                            }
                                          );
                                        }
                                        // console.log(selectedSubCategories);
                                        // console.log(
                                        //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                        // )
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor={`cbx-${100 + index}`}
                                      className="me-5 pe-4 cursor-pointer"
                                    >
                                      {one.name}
                                    </label>
                                  </label>
                                </div>
                                <br />
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <h4 className="h5 mb-0 pb-0">Size</h4>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center">
                            <label className="checkbox-container">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id="xs"
                                onChange={() => handleSizeChange("XS")}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor="xs"
                                className="me-5 pe-4 cursor-pointer"
                              >
                                XS
                              </label>
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <label className="checkbox-container">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id="s"
                                onChange={() => handleSizeChange("S")}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor="s"
                                className="me-5 pe-4 cursor-pointer"
                              >
                                S
                              </label>
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <label className="checkbox-container">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id="m"
                                onChange={() => handleSizeChange("M")}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor="m"
                                className="me-5 pe-4 cursor-pointer"
                              >
                                M
                              </label>
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <label className="checkbox-container w-100">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id="l"
                                onChange={() => handleSizeChange("L")}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor="l"
                                className="me-5 pe-1 cursor-pointer"
                              >
                                L
                              </label>
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <label className="checkbox-container w-100">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id="xl"
                                onChange={() => handleSizeChange("XL")}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor="xl"
                                className="me-5 pe-1 cursor-pointer"
                              >
                                XL
                              </label>
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <label className="checkbox-container w-100">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id="2xl"
                                onChange={() => handleSizeChange("2XL")}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor="2xl"
                                className="me-5 pe-1 cursor-pointer"
                              >
                                2XL
                              </label>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <h4 className="h5 pb-0 mb-0">Price</h4>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-8 w-100">
                          <div className="d-flex justify-content-between w-100">
                            <p>Range</p>
                            {/* <p>10 EGP - 500 EGP</p> */}
                          </div>
                          <div className="card-conteiner">
                            <div className="range-slider">
                              <Slider
                                getAriaLabel={() => "Price range"}
                                value={value}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={price.lowestPrice || 0}
                                max={price.highestPrice || 500}
                                className="main-clr-text2"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {priceValue.length > 0 && (
                                  <span style={{ fontSize: "14px" }}>
                                    {priceValue[0]} EGP - {priceValue[1]} EGP
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      onClick={resetFilters}
                      className="btn main-clr text-white me-3"
                    >
                      Reset
                    </button>
                    <button
                      className=" btn main-clr text-white my-3 w-50"
                      onClick={() => filter(currentPage, itemsPerPage)}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-100 OurProducts2">
            <div className="d-flex justify-content-end pe-5 mb-md-0 mb-2">
              {/* <p className="text-end pe-2 mt-1">Sort by latest</p> */}
              <Dropdown>
                {/* <Dropdown.Toggle
                  variant="success"
                  className="p-0 px-1 bg-white mt-md-3 text-dark border-0"
                  id="dropdown-basic"
                ></Dropdown.Toggle> */}

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Price</Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2"></Dropdown.Item>
        <Dropdown.Item href="#/action-3"></Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {products.length === 0 ? (
              <>
                <div className="d-flex justify-content-center align-items-center Available">
                  <h1 className="fw-bold">No Products Available</h1>
                </div>
              </>
            ) : (
              <div className="row w-100 mt-3 ps-md-2 gy-md-3 ms-md-2 ms-0">
                {sortedProducts.map((product, index) => (
                  <div
                    key={index}
                    className="col-md-3 col-6 cursor-pointer product"
                  >
                    <div className="producthead w-100">
                      {product.variants[0]?.isSale ? (
                        <div className="saleCard2 d-flex justify-content-around me-md-3 ">
                          <p className="text-white pt-1">
                            Sale {product.variants[0].salePercentage}%
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <Link
                        to={`/productdetails/${product._id}`}
                        className="text-decoration-none text-dark"
                      >
                        <img
                          src={product.images[0]}
                          className="w-100 h-100 product-img"
                          // style={{ minHeight: "513px", maxHeight: "513px" }}
                          alt="ourProducts1"
                        />
                      </Link>
                      {product.variants[0]?.isAvailable ? (
                        <div className="product-layer ms-1 d-flex w-100 align-items-center justify-content-center">
                          {localStorage.getItem("token") ? (
                            <img
                              src={productButton}
                              onClick={() => {
                                addToCart(product._id, product.variants[0]._id);
                              }}
                              width={170}
                              className="me-2 mt-1 img1 productButton"
                            />
                          ) : (
                            <Link
                              to={"/login"}
                              className="text-decoration-none"
                              onClick={() => toast.error("please login first")}
                            >
                              <img
                                src={productButton}
                                width={170}
                                className="me-2 mt-1 img1"
                              />
                            </Link>
                          )}

                          {localStorage.getItem("token") ? (
                            <div
                              className="wishlistbtn mb-1"
                              onClick={() => {
                                addToWishList(
                                  product._id,
                                  product.variants[0]._id
                                );
                              }}
                            >
                              {product.variants[0].isFav ? (
                                <i className="fa-solid fa-heart text-white"></i>
                              ) : (
                                <i className="fa-regular fa-heart text-white"></i>
                              )}
                            </div>
                          ) : (
                            <Link
                              to={"/login"}
                              className="text-decoration-none mb-1 me-md-0 me-1"
                              onClick={() => toast.error("please login first")}
                            >
                              <div className="wishlistbtn mb-1 ">
                                <i className="fa-regular fa-heart text-white"></i>
                              </div>
                            </Link>
                          )}
                        </div>
                      ) : (
                        <div className="product-layer ms-1 d-flex align-items-center justify-content-center">
                          <div className="btn main-clr text-white px-md-5 py-md-1 ms-md-0 ms-3 productButton">
                            Out Of Stock
                          </div>
                          <div
                            className="wishlistbtn mb-1 ms-2"
                            onClick={() => {
                              addToWishList(
                                product._id,
                                product.variants[0]._id
                              );
                            }}
                          >
                            {product.variants[0].isFav ? (
                              <i className="fa-solid fa-heart text-white"></i>
                            ) : (
                              <i className="fa-regular fa-heart text-white"></i>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <Link
                      to={`/productdetails/${product._id}`}
                      className="text-decoration-none text-dark"
                    >
                      <h4 className="h5 text-center pt-3">{product.name}</h4>
                      <div className="d-flex  justify-content-evenly mx-auto ourProductstext">
                        {product.variants[0]?.isSale ? (
                          <div className="d-flex">
                            <p className="text-decoration-line-through text-muted fw-bold">
                              {parseInt(product.variants[0].price) +
                                parseInt(product.variants[0].saleValue)}
                              EGP
                            </p>
                            <p className="ms-1 text-danger fw-bold">
                              {product.variants[0].price} EGP
                            </p>
                          </div>
                        ) : (
                          <p>{product.minPrice.toLocaleString()} EGP</p>
                        )}
                        {product?.rating === 0 ? (
                          ""
                        ) : (
                          <>
                            <p> | </p>
                            <div className="d-flex align-items-center align-content-center justify-content-center">
                              <div className="text-start mb-3 d-flex">
                                {product.rating === 1 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 2 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 3 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 4 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr "></i>
                                    <i className="fa-solid fa-star font-sm  "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 5 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr "></i>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {getPageNumbers().map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber)}
                    linkClassName={
                      pageNumber === currentPage ? "pagination-item" : ""
                    }
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
