import React from 'react'
import Navbar from '../Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import { Offline, Online } from "react-detect-offline";
import Footer from '../Footer/Footer';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

export default function Layout() {
  return <>
  <Navbar/>
  {/* <Offline> <div className='offline z-3 text-center'> <h1>There is no internet connection </h1></div></Offline> */}
<ScrollToTop>
<Outlet></Outlet>
</ScrollToTop>
  <Footer/>
  </>
}
