// Import the functions you need from the SDKs you need
// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCj-Es4gxNgB--7iGvAbvHOu7JC2afaMVU",
    authDomain: "kanza-files.firebaseapp.com",
    projectId: "kanza-files",
    storageBucket: "kanza-files.appspot.com",
    messagingSenderId: "897661211742",
    appId: "1:897661211742:web:3419eab3a6141af533d0cf"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider};