import React, { useContext, useEffect, useState } from "react";
import ProductDetailsVerticalSlider from "../ProductDetailsVerticalSlider/ProductDetailsVerticalSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import circleImg from "../../Assets/Icons/Philadelphia_Branding_Photography_0001-1024x751 1.png";
import productButton from "../../Assets/Icons/span.f-badge.png";
import { Link, Outlet, useHref, useParams } from "react-router-dom";
import axios from "axios";
import { FallingLines } from "react-loader-spinner";
import { isMobile } from "mobile-device-detect";
import starIcon from "../../Assets/Icons/star.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import toast from "react-hot-toast";
import { CartContext } from "../Context/userContext";
import ReactImageMagnify from "react-image-magnify";

export default function ProductDetails() {
  const [selectorClr, setSelectorClr] = useState(false);
  const [clr, setclr] = useState(false);
  const [imgssrc, setImgssrc] = useState("");
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [varianId, setVarianId] = useState("");
  const [test, settest] = useState(false);
  const [test2, settest2] = useState(false);
  const [sizePrice, setSizePrice] = useState("");
  const [variantStock, setvariantStock] = useState("");
  const [sale, setSale] = useState("");
  const [salePercentage, setSalePercentage] = useState("");
  const [saleValue, setsaleValue] = useState("");
  const [count, setCount] = useState(1);
  const [days, setDays] = React.useState(3);
  const [hours, setHours] = React.useState(24);
  const [minutes, setMinutes] = React.useState(59);
  const [seconds, setSeconds] = React.useState(60);
  const [testing, settesting] = useState("");
  const [rating, setRating] = useState(false);
  const [review, setReview] = useState("");
  const [allReviws, setAllReviws] = useState([]);
  const [clrSelect, setClrSelect] = useState(false);

  let { setNumberOfCartItems, numberOfCartItems } = useContext(CartContext);
  async function getproducts2(id) {
    if (localStorage.getItem("token")) {
      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/products/relatedProducts/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setProduct(data.products);
    } else {
      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/products/all`
      );
      setProduct(data.products);
    }
  }
  async function addToWishList(productId, varianId) {
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToWishlist`,
        {
          productId: productId,
          variantId: varianId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function addToCartFromRelated(productId, varianId) {
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToCart`,
        {
          productId: productId,
          variantId: varianId,
          quantity: 1,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function addToCart() {
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToCart`,
        {
          productId: productId,
          variantId: varianId ? varianId : product?.variants[0]._id,
          quantity: count,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setNumberOfCartItems(response?.data?.cart?.products?.length);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          className: "text-center",
        });
      });
  }

  function imgsrc(e) {
    setImgssrc(e.target.src);
  }

  const handelIncrease = () => {
    if (count < variantStock) {
      setCount(count + 1);
    } else {
      toast("only in stock " + variantStock, {
        icon: "💁‍♀️",
        className: "fw-bold",
      });
    }
  };
  const handelDecrease = () => {
    setCount(count - 1);
    if (count === 1) {
      setCount(1);
    }
  };
  const settings = {
    infinite: false,
    autoplay: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          autoplay: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
          autoplay: false,
        },
      },
    ],
  };

  let timer;

  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);
      if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
      if (minutes === 0) {
        setHours(hours - 1);
        setMinutes(59);
      }
      if (hours === 0) {
        setDays(days - 1);
        setHours(24);
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  const [toggleState, setToggleState] = useState(1);
  function toggleTab(index) {
    setToggleState(index);
  }
  const [loading, setLoading] = useState(false);
  let { productId } = useParams();
  let href = useHref();
  const [product, setProduct] = useState(null);

  async function getproductDetails(id) {
    setLoading(true);
    if (localStorage.getItem("token")) {
      axios
        .get(`https://kanza-back-end.onrender.com/products/all/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const product = response.data.product;
          if (Array.isArray(product.variants)) {
            product.variants.sort((a, b) => {
              if (a.isAvailable && !b.isAvailable) return -1;
              if (!a.isAvailable && b.isAvailable) return 1;
              return 0;
            });
          }
          const firstAvailableVariant = product.variants[0] || {};
          setProduct(product);
          getReviews(product._id);
          setSize(firstAvailableVariant.size);
          setColor(firstAvailableVariant.color.name);
          setSizePrice(firstAvailableVariant.price.toLocaleString());
          setvariantStock(firstAvailableVariant.stock);
          setSale(firstAvailableVariant.isSale);
          setSalePercentage(firstAvailableVariant.salePercentage);
          setsaleValue(firstAvailableVariant.saleValue);
          setClrSelect(firstAvailableVariant.color.name);
          setclr(firstAvailableVariant.color.name);
          setVarianId(firstAvailableVariant._id);
          setClrSelect(true);
          setLoading(false);
        });
    } else {
      setLoading(true);
      axios
        .get(`https://kanza-back-end.onrender.com/products/all/${id}`)
        .then((response) => {
          const product = response.data.product;
          if (Array.isArray(product.variants)) {
            product.variants.sort((a, b) => {
              if (a.isAvailable && !b.isAvailable) return -1;
              if (!a.isAvailable && b.isAvailable) return 1;
              return 0;
            });
          }

          const firstAvailableVariant = product.variants[0] || {};

          setProduct(product);
          getReviews(product._id);
          setSize(firstAvailableVariant.size);
          setColor(firstAvailableVariant.color.name);
          setSizePrice(firstAvailableVariant.price.toLocaleString());
          setvariantStock(firstAvailableVariant.stock);
          setSale(firstAvailableVariant.isSale);
          setSalePercentage(firstAvailableVariant.salePercentage);
          setsaleValue(firstAvailableVariant.saleValue);
          setClrSelect(firstAvailableVariant.color.name);
          setclr(firstAvailableVariant.color.name);
          setVarianId(firstAvailableVariant._id);
          setClrSelect(true);
          setLoading(false);
        });
    }
  }

  const [rProducts, setrProducts] = useState([]);

const sortedProducts = rProducts.map((product) => {
  const sortedVariants = [...product.variants].sort((a, b) => {
    if (a.isAvailable && !b.isAvailable) return -1;
    if (!a.isAvailable && b.isAvailable) return 1;
    return 0;
  });

  return {
    ...product,
    variants: sortedVariants,
  };
});

  async function getRelatedProducts(id) {
    let { data } = await axios.get(
      `https://kanza-back-end.onrender.com/products/relatedProducts/${id}?&page=1&perPage=10`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    setrProducts(data?.relatedProduct);
  }

  useEffect(() => {
    getproductDetails(productId);
  }, []);

  useEffect(() => {
    getRelatedProducts(productId);
  }, []);

  async function addReview(id, rating, review) {
    axios
      .post(
        `https://kanza-back-end.onrender.com/reviews/${id}`,
        {
          rating: rating,
          review: review,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        // console.log(response);
        getReviews(response?.data?.product?._id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // console.log(err);
      });
  }

  const handelTextAreaChange = (event) => {
    setReview(event.target.value);
  };

  async function getReviews(id) {
    if (localStorage.getItem("token")) {
      axios
        .get(`https://kanza-back-end.onrender.com/reviews/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setAllReviws(response?.data?.reviews);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      axios
        .get(`https://kanza-back-end.onrender.com/reviews/${id}`)
        .then((response) => {
          setAllReviws(response?.data?.reviews);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading">
          {isMobile ? (
            <FallingLines
              color="#044F5C"
              width="150"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          ) : (
            <FallingLines
              color="#044F5C"
              width="300"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          )}
        </div>
      ) : (
        <section className=" overflow-hidden py-md-5 py-3 mt-md-5 ProductDetails">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-2 sliderdetails pe-3">
                <div className="ms-5 ProductDetailsVerticalSlider">
                  {product?.images?.map((img, index) => (
                    <Swiper
                      key={index}
                      direction="vertical"
                      slidesPerView={1}
                      spaceBetween={100}
                      className="mySwiper2 ms-4"
                    >
                      <SwiperSlide>
                        <img
                          src={img}
                          style={{ minHeight: "200px", maxHeight: "200px" }}
                          onClick={imgsrc}
                          className="px-4 py-2"
                        />
                      </SwiperSlide>
                    </Swiper>
                  ))}
                </div>
              </div>

              <div className="col-md-4">
                {product?.images?.length === 1 ? (
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imgssrc ? imgssrc : product?.images[0],
                      },
                      largeImage: {
                        src: imgssrc ? imgssrc : product?.images[0],
                        width: 1200,
                        height: 1800,
                      },
                      imageStyle: {
                        minHeight: "600px ",
                        maxHeight: "600px",
                        height: "600px",
                      },
                    }}
                  />
                ) : isMobile ? (
                  <Slider {...settings}>
                    {product?.images?.map((img, index) => (
                      <div
                        key={index}
                        className="col-md-3 px-md-2 px-0  col-6 cursor-pointer product"
                      >
                        <img
                          src={img}
                          style={{ minHeight: "600px", maxHeight: "600px" }}
                          alt="img"
                          className="w-100"
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imgssrc ? imgssrc : product?.images[0],
                      },
                      largeImage: {
                        src: imgssrc ? imgssrc : product?.images[0],
                        width: 1200,
                        height: 1800,
                      },
                      imageStyle: {
                        minHeight: "600px ",
                        maxHeight: "600px",
                        height: "600px",
                      },
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-md-0 mt-3">
                <div>
                  <h4>{product?.name}</h4>
                </div>
                <div></div>
                <div className="d-flex align-content-center align-items-center w-100">
                  {sale ? (
                    <>
                      <div className="w-100">
                        <div className="d-flex w-100">
                          <p className="text-decoration-line-through text-muted fs-5">
                            {parseInt(sizePrice) + parseInt(saleValue)} EGP
                          </p>
                          <p className="ms-2 text-danger fw-bold fs-5">
                            {sizePrice} EGP
                          </p>

                          <div className="rounded-4 ms-2 px-2 py-0 bg-danger text-white d-flex justify-content-center align-items-center font-sm mb-3 fw-bold py-1">
                            Sale {salePercentage} %
                          </div>
                        </div>
                        {/* <div className="hurryUp d-flex justify-content-between align-items-center py-1 w-100">
                    <h6 className=" fs-5 huryupText fw-bold">
                      Hurry up! Sale ends in:
                    </h6>
                    <p className="me-3 fw-bold huryupcounter">
                      {days} D : {hours} H : {minutes} M : {seconds} S
                    </p>
                  </div> */}
                      </div>
                    </>
                  ) : (
                    <>
                      {sizePrice ? (
                        <p className="fw-bold fs-5"> {sizePrice} EGP</p>
                      ) : (
                        <>
                          {product?.variants[0]?.isSale ? (
                            <>
                              <div className="w-100">
                                <div className="d-flex w-100">
                                  <p className="me-3">
                                    {product?.variants[0]?.price} EGP
                                  </p>
                                  <p className="text-decoration-line-through text-muted">
                                    {product?.variants[0]?.price +
                                      product?.variants[0]?.saleValue}
                                    EGP
                                  </p>
                                  <div className="rounded-4 ms-2 px-2 py-0 bg-danger text-white d-flex justify-content-center align-items-center font-sm mb-3 fw-bold py-1 ">
                                    Sale {product?.variants[0]?.salePercentage}{" "}
                                    %
                                  </div>
                                </div>
                                {/* <div className="hurryUp d-flex justify-content-between align-items-center py-1 w-100">
                    <h6 className=" fs-5 huryupText fw-bold">
                      Hurry up! Sale ends in:
                    </h6>
                    <p className="me-3 fw-bold huryupcounter">
                      {days} D : {hours} H : {minutes} M : {seconds} S
                    </p>
                  </div> */}
                              </div>
                            </>
                          ) : (
                            <p className=" fw-bold">
                              {product?.variants[0]?.price} EGP
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="mt-2 prog">
                  {variantStock === 0 ? (
                    <h6 className="mb-3 text-danger">
                      Sorry, this item is out of stock!
                    </h6>
                  ) : (
                    <h6 className="mb-3">
                      Only {variantStock} items left in stock!
                    </h6>
                  )}
                </div>

                <>
                  {size ? (
                    <h6 className="mb-0 pb-0 ">Size: {size}</h6>
                  ) : (
                    <h6 className="mb-0 pb-0 ">
                      {" "}
                      Size: {product?.variants[0]?.size}
                    </h6>
                  )}
                  <div className="d-flex mb-2 py-2 ">
                    {product?.variants
                      // Filter variants based on the currently set color
                      .filter((variant) => variant.color.name === clr)
                      .map((variant, index) => (
                        <div
                          key={index}
                          className={
                            variant.size === size
                              ? "border rounded-3 sizebox me-2 px-3 cursor-pointer mb-2"
                              : "mb-2 border  rounded-3 me-2 px-3 cursor-pointer"
                          }
                          onClick={() => {
                            setVarianId(variant._id);
                            setSize(variant.size);
                            setSizePrice(variant.price);
                            setvariantStock(variant.stock);
                            setSale(variant.isSale);
                            setSalePercentage(variant.salePercentage);
                            setsaleValue(variant.saleValue);
                            setclr(variant.color.name); // Set color based on selected size
                          }}
                        >
                          {variant.size
                            ? variant.size
                            : product.variants[0].size}
                        </div>
                      ))}
                  </div>
                  <div>
                    <h6 className="colorName mt-2 ">
                      Color:
                      <span>
                        {""} {clr ? clr : product?.variants[0]?.color?.name}
                      </span>
                    </h6>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    {product?.variants
                      ?.filter(
                        (variant, index, self) =>
                          index ===
                          self.findIndex(
                            (v) => v?.color?.name === variant?.color?.name
                          )
                      )
                      // Map over the unique colors
                      .map((variant, index) => (
                        <div className="d-flex me-2 justify-content-evenly align-items-center ">
                          <label key={index} className="mb-2 cursor-pointer ">
                            <div
                              className={
                                selectorClr === variant?.color?.value
                                  ? "checkmark2"
                                  : "check"
                              }
                              style={{
                                backgroundColor: `${variant?.color?.value}`,
                              }}
                            ></div>
                            <input
                              style={{
                                backgroundColor: `${variant?.color?.value}`,
                              }}
                              type="checkbox"
                              className="d-none"
                              checked={clrSelect}
                              onClick={() => {
                                setSelectorClr(variant?.color?.value);
                                setclr(variant?.color?.name);
                                setVarianId(variant?._id);
                                setSize(variant?.size);
                                setSizePrice(variant?.price);
                                setvariantStock(variant?.stock);
                                setSale(variant?.isSale);
                                setSalePercentage(variant?.salePercentage);
                                setsaleValue(variant?.saleValue);
                                setClrSelect(true);
                              }}
                            />
                          </label>
                        </div>
                      ))}
                  </div>
                  {variantStock === 0 ? (
                    <div></div>
                  ) : (
                    <>
                      <div className="QuantityText mt-2">
                        <h6>Quantity</h6>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="Quantity w-25 px-md-3 px-3 rounded-3">
                          <span
                            className="cursor-pointer"
                            onClick={handelDecrease}
                          >
                            <i className="fa-solid fa-minus"></i>
                          </span>
                          <span>
                            {variantStock
                              ? variantStock > count
                                ? count
                                : variantStock
                              : product?.variants[0]?.stock > count
                              ? count
                              : product?.variants[0]?.stock}
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={handelIncrease}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </span>
                        </div>
                        {localStorage.getItem("token") ? (
                          <button
                            onClick={addToCart}
                            className="btn btn-outline-dark w-75 ms-5 addToCartBtn"
                          >
                            Add To Cart
                          </button>
                        ) : (
                          <Link to={"/login"} className="w-100">
                            <button
                              onClick={() => toast.error("please login first")}
                              className="btn btn-outline-dark w-75 ms-5 addToCartBtn"
                            >
                              Add To Cart
                            </button>
                          </Link>
                        )}
                      </div>
                    </>
                  )}
                </>
              </div>
              <div className="col-md-12" style={{ marginTop: "3rem" }}>
                <div className="d-flex border-bottom cursor-pointer">
                  <div
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                  >
                    Description
                  </div>
                  <div
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                  >
                    Review
                  </div>
                </div>
                <div className="content-tabs mt-2">
                  <div
                    className={toggleState === 1 ? "active-content" : "content"}
                  >
                    {product?.description?.split("\n").map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </div>
                  <div
                    className={toggleState === 2 ? "active-content" : "content"}
                  >
                    <section>
                      <h5 className="py-3 fw-bold">Customers Reviews</h5>
                      <div className="row">
                        {allReviws.map((review) => (
                          <>
                            <div className="col-md-12 d-flex align-items-center">
                              <img
                                src={review?.user?.image}
                                className="circleImg rounded-circle bg-light me-2"
                                style={{ minWidth: "60px ", maxWidth: "60px" }}
                                alt="circleImg"
                              />
                              <div>
                                <h6 className="mb-0 pb-0">
                                  {review?.user?.firstName}{" "}
                                  {review?.user?.lastName}
                                </h6>
                                <div className="text-start">
                                  {review.rating === 1 ? (
                                    <>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                      <i className="fa-solid fa-star "></i>
                                      <i className="fa-solid fa-star "></i>
                                      <i className="fa-solid fa-star "></i>
                                      <i className="fa-solid fa-star "></i>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {review.rating === 2 ? (
                                    <>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                      <i className="fa-solid fa-star rating-clr "></i>
                                      <i className="fa-solid fa-star "></i>
                                      <i className="fa-solid fa-star "></i>
                                      <i className="fa-solid fa-star "></i>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {review.rating === 3 ? (
                                    <>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                      <i className="fa-solid fa-star  rating-clr"></i>
                                      <i className="fa-solid fa-star  rating-clr"></i>
                                      <i className="fa-solid fa-star "></i>
                                      <i className="fa-solid fa-star "></i>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {review.rating === 4 ? (
                                    <>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                      <i className="fa-solid fa-star  rating-clr"></i>
                                      <i className="fa-solid fa-star  rating-clr"></i>
                                      <i className="fa-solid fa-star rating-clr "></i>
                                      <i className="fa-solid fa-star  "></i>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {review.rating === 5 ? (
                                    <>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                      <i className="fa-solid fa-star  rating-clr"></i>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                      <i className="fa-solid fa-star rating-clr "></i>
                                      <i className="fa-solid fa-star rating-clr"></i>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3 border-bottom">
                              {review?.review
                                ?.split("\n")
                                .map((line, index) => (
                                  <p key={index}>{line}</p>
                                ))}
                            </div>
                          </>
                        ))}
                        {product?.canReview && localStorage.getItem("token") ? (
                          <>
                            <h5 className="pt-3 fw-bold">Add Your Review</h5>
                            <p className="mb-0">Your Rating</p>
                            <div className="d-flex justify-content-start">
                              <div class="rating text-start">
                                <input
                                  type="radio"
                                  id="star5"
                                  name="rate"
                                  onClick={() => {
                                    setRating(1);
                                  }}
                                  value="5"
                                />
                                <label for="star5" title="text"></label>
                                <input
                                  type="radio"
                                  id="star4"
                                  name="rate"
                                  onClick={() => {
                                    setRating(4);
                                  }}
                                  value="4"
                                />
                                <label for="star4" title="text"></label>
                                <input
                                  type="radio"
                                  id="star3"
                                  name="rate"
                                  onClick={() => {
                                    setRating(3);
                                  }}
                                  value="3"
                                />
                                <label for="star3" title="text"></label>
                                <input
                                  type="radio"
                                  id="star2"
                                  name="rate"
                                  onClick={() => {
                                    setRating(2);
                                  }}
                                  value="2"
                                />
                                <label for="star2" title="text"></label>
                                <input
                                  type="radio"
                                  id="star1"
                                  name="rate"
                                  onClick={() => {
                                    setRating(1);
                                  }}
                                  value="1"
                                />
                                <label for="star1" title="text"></label>
                              </div>
                            </div>

                            <div>
                              <label htmlFor="Review" className="my-2 ">
                                Your Review
                              </label>
                              <textarea
                                name="Review"
                                id="review"
                                cols="10"
                                rows="5"
                                required
                                className="form-control"
                                placeholder="Enter Your Review"
                                onChange={handelTextAreaChange}
                              ></textarea>
                              <button
                                className="btn main-clr text-white my-3 py-2 px-3"
                                onClick={() => {
                                  addReview(product?._id, rating, review);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </section>
                  </div>
                </div>
                {rProducts.length === 0 ? (
                  ""
                ) : (
                  <>
                    <div className="Related">
                      <h3 className="position-relative">Related Products</h3>
                    </div>
                    <div className="row my-4 w-100 mx-auto ms-sm-0 ms-1">
                      <Slider {...settings}>
                        {sortedProducts.map((product, index) => (
                          <div
                            key={index}
                            className="col-md-3 px-md-2 px-0  col-6 cursor-pointer product"
                          >
                            <div className="producthead">
                              {product.variants[0]?.isSale ? (
                                <div className="saleCard d-flex justify-content-around me-md-3 ">
                                  <p className="text-white pt-1">
                                    Sale {product.variants[0].salePercentage}%
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              <Link
                                className="text-decoration-none text-dark"
                                onClick={() => {
                                  getproductDetails(product._id);
                                }}
                                to={`/productdetails/${product._id}`}
                              >
                                <img
                                  src={product.images[0]}
                                  className="w-100 h-100"
                                  // style={{
                                  //   minHeight: "513px",
                                  //   maxHeight: "513px",
                                  // }}
                                  alt="ourProducts1"
                                />{" "}
                              </Link>
                              {product.variants[0]?.isAvailable ? (
                                <div className="product-layer d-flex align-items-center justify-content-end ms-md-1">
                                  {localStorage.getItem("token") ? (
                                    <img
                                      src={productButton}
                                      onClick={() => {
                                        addToCart(
                                          product._id,
                                          product.variants[0]._id
                                        );
                                      }}
                                      width={170}
                                      className="me-2 mt-1 h-100 productButton "
                                    />
                                  ) : (
                                    <Link
                                      to={"/login"}
                                      className="text-decoration-none"
                                      onClick={() =>
                                        toast.error("please login first")
                                      }
                                    >
                                      <img
                                        src={productButton}
                                        width={170}
                                        className="me-2 mt-1 h-100 productButton"
                                      />
                                    </Link>
                                  )}

                                  {localStorage.getItem("token") ? (
                                    <div
                                      className="wishlistbtn mb-1"
                                      onClick={() => {
                                        addToWishList(
                                          product._id,
                                          product.variants[0]._id
                                        );
                                        getRelatedProducts(product._id);
                                      }}
                                    >
                                      {product.variants[0].isFav ? (
                                        <i className="fa-solid fa-heart text-white"></i>
                                      ) : (
                                        <i className="fa-regular fa-heart text-white"></i>
                                      )}
                                    </div>
                                  ) : (
                                    <Link
                                      to={"/login"}
                                      className="text-decoration-none"
                                      onClick={() =>
                                        toast.error("please login first")
                                      }
                                    >
                                      <div className="wishlistbtn mb-1">
                                        <i className="fa-regular fa-heart text-white"></i>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ) : (
                                <div className="product-layer ms-4 d-flex align-items-center justify-content-end">
                                  <div className="btn main-clr text-white px-4 py-1">
                                    Out Of Stock
                                  </div>
                                  <div
                                    className="wishlistbtn mb-1 ms-2"
                                    onClick={() => {
                                      addToWishList(
                                        product._id,
                                        product.variants[0]._id
                                      );
                                      getRelatedProducts(product._id);
                                    }}
                                  >
                                    {product.variants[0].isFav ? (
                                      <i className="fa-solid fa-heart text-white"></i>
                                    ) : (
                                      <i className="fa-regular fa-heart text-white"></i>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <Link
                              className="text-decoration-none text-dark"
                              to={`/productdetails/${product._id}`}
                            >
                              <div className="text-center">
                                <h4 className="h5 text-center pt-3">
                                  {product?.name}
                                </h4>
                                <div className="d-flex w-75 justify-content-evenly mx-auto ourProductstext">
                                  {product?.variants[0]?.isSale ? (
                                    <div className="d-flex">
                                      <p className="text-decoration-line-through text-muted fw-bold">
                                        {parseInt(product.variants[0].price) +
                                          parseInt(
                                            product.variants[0].saleValue
                                          )}
                                        EGP
                                      </p>
                                      <p className="ms-1 text-danger fw-bold">
                                        {product.variants[0].price} EGP
                                      </p>
                                    </div>
                                  ) : (
                                    <p>
                                      {product.minPrice.toLocaleString()} EGP
                                    </p>
                                  )}
                                  {product?.rating === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      <p> | </p>
                                      <div className="d-flex align-items-center align-content-center justify-content-center">
                                        <p>{product.rating}</p>
                                        <img
                                          src={starIcon}
                                          className="mb-3 ps-2 starIcon"
                                          alt="starIcon"
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
