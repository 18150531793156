import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { CartContext } from '../Context/userContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Vortex } from 'react-loader-spinner';

export default function Payment() {
  let { setNumberOfCartItems, getCart } = useContext(CartContext);

  let addressString = localStorage.getItem('addressString');
  let addressObj = JSON.parse(addressString);
  // console.log(addressObj);

  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(false);
  const [showMethod, setShowMethod] = useState(false);
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(false);
  const [shippingfee, setShippingfee] = useState(localStorage.getItem('shippingfee'));
  const [discountType, setDiscountType] = useState(false);
  let totalPriceAfterPromoCode = parseInt(localStorage.getItem('priceAfterSale'));

  useEffect(() => {
    getCartProducts();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('priceAftersale') === 'promo code') {
      setDiscountType('promo code');
    } else if (localStorage.getItem('priceAftersale') === 'loyalty card') {
      setDiscountType('loyalty card');
    }
  }, []);

  async function getCartProducts() {
    if (localStorage.getItem('token')) {
      let { data } = await getCart();
      setTotalPrice(Math.round(data?.total));
    } else {
      navigate('/login');
    }
  }

  async function placeOrder(address, method) {
    setLoading(true);
    axios.post(
      'https://kanza-back-end.onrender.com/users/order',
      {
        address: address,
        paymentMethod: method,
        discountType: localStorage.getItem('priceAftersale') ? discountType : 'none',
      },
      {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }
    ).then(response => {
      toast.success(response.data.message);
      setNumberOfCartItems(0);
      localStorage.removeItem('shippingfee')
      localStorage.removeItem('priceAfterSale')
      localStorage.removeItem('addressString')
      localStorage.removeItem('priceAftersale')
      setLoading(false);
      if (method === 'cod') {
        navigate('/profile/orders');
      } else if (method === 'card') {
        window.location.href = response?.data?.data?.paymentKey;
      }
    }).catch(err => {
      toast.error(err.response.data.message);
      setLoading(false);
    });
  }

  let total = totalPrice + parseInt(shippingfee);
  let totalPriceAfterPromoCodeAndShipping = parseInt(totalPriceAfterPromoCode) + parseInt(shippingfee);

  return (
    <>
      <section className='mt-5 pt-5 mb-5 pb-5 container'>
        <div className="row">
          <div className="col-md-12 main-cover mt-3">
            <h2 className='cover-text'>Payment</h2>
          </div>
          <div className='d-flex w-75 mx-auto justify-content-between mt-3'>
            <Link className='text-decoration-none' to={'/cart'}>
              <p className='main-clr-text'>Cart</p>
            </Link>
            <Link className='text-decoration-none' to={'/checkout'}>
              <p className='main-clr-text ps-4'>Checkout</p>
            </Link>
            <Link className='text-decoration-none' to={'/payment'}>
              <p className='text-black'>Payment</p>
            </Link>
          </div>
          <div className='d-flex w-75 mx-auto justify-content-between mt-1 align-items-center'>
            <Link className='text-decoration-none' to={'/cart'}>
              <div className="cartCircle2"></div>
            </Link>
            <div className='cartLine'></div>
            <Link className='text-decoration-none' to={'/checkout'}>
              <div className="cartCircle3"></div>
            </Link>
            <div className='cartLine'></div>
            <div className="cartCircle"></div>
          </div>
        </div>
        <div className='w-75 mx-auto d-flex justify-content-between mt-5'>
          <h6>Subtotal(2 items)</h6>
          <h6>{totalPriceAfterPromoCode ? totalPriceAfterPromoCode.toLocaleString() : totalPrice.toLocaleString()} EGP</h6>
        </div>
        <div className='w-75 mx-auto d-flex justify-content-between mt-2'>
          <h6>Shipping</h6>
          <h6>{shippingfee === null ? 'select address first' : shippingfee + ' EGP'} </h6>
        </div>
        <div className='w-75 mx-auto d-flex justify-content-between mt-2'>
          <h6>Total Price</h6>
          {totalPriceAfterPromoCode ? <h6>{totalPriceAfterPromoCodeAndShipping.toLocaleString() + ' EGP'}</h6> : <h6>{shippingfee === null  ? 'select address first' : total.toLocaleString() + ' EGP'} </h6>}
        </div>
        <Dropdown className='w-75 mx-auto my-4 mb-md-0 mb-5 pb-4'>
          <Dropdown.Toggle variant="success" className="bg-white text-black btn-dark w-100 d-flex justify-content-between align-items-center main-clr text-white address-w2" id="dropdown-basic">
            {showMethod ? showMethod : 'select payment method'}
          </Dropdown.Toggle>
          <Dropdown.Menu className="address-w2 checkoutDropdown">
            <Dropdown.Item className="w-100" onClick={() => { setMethod('card'); setShowMethod('Credit card'); }}>Credit card</Dropdown.Item>
            <Dropdown.Item className="w-100" onClick={() => { setMethod('cod'); setShowMethod('Cash On Delivery'); }}>Cash On Delivery</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className='text-end w-75 mx-auto'>
          <Link to={'/checkout'}>
            <button className='btn btn-outline-dark px- py-1 me-3 backButton'><i className="fa-solid fa-chevron-left"></i></button>
          </Link>
          {loading ? (
            <button className='btn main-clr text-white px-3 py-1'>
              <Vortex visible={true} height="30" width="30" ariaLabel="vortex-loading" wrapperStyle={{}} wrapperClass="vortex-wrapper" colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']} />
            </button>
          ) : (
            <button onClick={() => placeOrder(addressObj, method)} className='btn main-clr text-white px-3 py-1'>Submit</button>
          )}
        </div>
      </section>
    </>
  );
}
