import React, { useState } from 'react'
import subImg1 from '../../Assets/Images/sub1.png'
import subImg2 from '../../Assets/Images/sub 2.png'
import axios from 'axios'
import toast from 'react-hot-toast'

export default function Subscribe() {

  const [email, setEmail] = useState(null)

  async function subscribe() {
    axios.post(`https://kanza-back-end.onrender.com/contactUs/subscription` , {
      "email":email
    })
    .then(response => {
      // console.log(response);
      toast.success(response.data.message)
    }).catch(err  => {
      console.log(err);
      toast.error(err.response.data.message)
    })
  }

  const handelEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return <>
  <section className='container py-5 subscribe'>
    <div className='row align-items-center justify-content-center'>
    <div className="col-md-3 col-6">
    <div>
        <img src={subImg1} alt="subImg1"/>
    </div>
    </div>
    <div className="col-md-6">
        <div className='subscribeCard p-3'>
            <h4 className='text-center h2 py-2'>Subscribe To Our Newsletter</h4>
            <input type="email" name="email" id="email" placeholder=' Enter Your Email' className='form-control' onChange={handelEmailChange} />
        </div>
        <div className='text-center mt-4'>
        <button className='text-white btn btn-dark' onClick={subscribe}>Subscribe Now</button>

        </div>

    </div>
    <div className="col-md-3 col-6">
    <img src={subImg2} alt="subImg2"/>
    </div>
    </div>
  </section>
  
  </>
}
