import React from "react";
import { Link } from "react-router-dom";

export default function WashingInstructions() {
  return (
    <>
      <section>
        <div className="container py-5 mt-md-4">
          <div className="row mt-md-4">
            <div className="col-md-12 main-cover">
              <h2 className="cover-text text-center">Washing Instructions </h2>
            </div>
            <div className="col-md-12">
              <p className="text-center mt-5 pb-5">
                At Kanza, we believe in empowering our customers with the
                knowledge to care for their clothing sustainably. When it comes
                to our garments, we recommend washing with cold water to
                preserve both the fabric's integrity and vibrant colors. For
                optimal results, we advise separating colors, especially during
                the initial wash. This ensures that any potential color bleeding
                is minimized, allowing each garment to maintain its original
                brilliance . Following these simple care instructions will help
                prolong the life of your garment and keep it looking fresh
                wear after wear.
              </p>
              <strong>
                If you have any questions or concerns, please{" "}
                <Link className="text-black" to={"/contactus"}>
                  {" "}
                  contact us.
                </Link>
              </strong>
              <p className="fw-bold mb-5">
                Thank you for choosing Kanza! We hope you enjoy your
                shopping experience.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
